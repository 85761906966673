import styled from "styled-components";

export const Bigsection = styled.section`
  background: #000;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  padding-top: 5rem;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    width: 100%;
    padding-top: 8rem;
  }
  .left {
    flex: 1;
    .countries {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.5rem;
      max-width: 100%;
      .firstRow {
        display: flex;
        align-items: flex-start;
        gap: 1.25rem;
        flex-wrap: wrap;

        .singleCountry {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.25rem;
          h1 {
            color: #fff;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
          }
          p:nth-child(2) {
            color: #fff;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
          p:nth-child(3) {
            color: #fff;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: lowercase;
          }
          p:nth-child(4) {
            color: #fff;
            text-align: right;
            font-family: Poppins;
            font-size: 1.29413rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    input,
    textarea {
      width: 100%;
      background: #161616;
      border-radius: 2px 2px 0px 0px;
      color: white;
      margin-bottom: 1rem;
      padding: 20px;
      color: #fff;
      font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 117%; /* 1.17rem */
      text-transform: uppercase;
      opacity: 0.8;

      &::placeholder {
        color: white;
        color: #fff;
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 117%; /* 1.17rem */
        text-transform: uppercase;
        opacity: 0.8;
      }
    }
    textarea {
      height: 10.1875rem;
    }

    button {
      background: #d9d9d9;
      border-radius: 4px;
      width: 100%;
      text-transform: uppercase;
      color: #000000;
      font-weight: 700;
      font-size: 16px;
      padding: 15px 0;
      cursor: pointer;
    }
  }
  @media (max-width: 767px) {
    .container {
      flex-direction: column;
      gap: 3rem;
      padding-top: 10rem;
    }
  }
`;
