import orsIcon1 from "../../../assets/ICONS/ORS/WHY/ORS_Convenience.png";
import orsIcon2 from "../../../assets/ICONS/ORS/WHY/ORS_Reach potential clients.png";
import orsIcon3 from "../../../assets/ICONS/ORS/WHY/ORS_Transparency.png";
import orsIcon4 from "../../../assets/ICONS/ORS/WHY/ORS_effeciency.png";
import orsIcon5 from "../../../assets/ICONS/ORS/WHY/ORS_personalization.png";
import ibsIcon1 from "../../../assets/ICONS/IBS/WHY/IBS_Competitive Edge .png";
import ibsIcon2 from "../../../assets/ICONS/IBS/WHY/IBS_Customized Solutions.png";
import ibsIcon3 from "../../../assets/ICONS/IBS/WHY/IBS_Enhanced Property Showcasing.png";
import ibsIcon4 from "../../../assets/ICONS/IBS/WHY/IBS_High-Quality.png";
import ibsIcon5 from "../../../assets/ICONS/IBS/WHY/IBS_Seamless User Experience.png";
import ipmIcon1 from "../../../assets/ICONS/Interactive Physical Model/WHY/IPM_Customized Information-31.png";
import ipmIcon2 from "../../../assets/ICONS/Interactive Physical Model/WHY/IPM_Differentiation in the Market.png";
import ipmIcon3 from "../../../assets/ICONS/Interactive Physical Model/WHY/IPM_Enhanced Engagement-30.png";
import ipmIcon4 from "../../../assets/ICONS/Interactive Physical Model/WHY/IPM_Immersive Visualization-28.png";
import ipmIcon5 from "../../../assets/ICONS/Interactive Physical Model/WHY/IPM_Interactive Exploration-29.png";
import ipmIcon6 from "../../../assets/ICONS/Interactive Physical Model/WHY/IPM_Memorable Impressions-33.png";
import phmIcon1 from "../../../assets/ICONS/Physical Model/WHY/PM_Attention to Detail.png";
import phmIcon2 from "../../../assets/ICONS/Physical Model/WHY/PM_Customization and Personalization.png";
import phmIcon3 from "../../../assets/ICONS/Physical Model/WHY/PM_Effective Communication Tool.png";
import phmIcon4 from "../../../assets/ICONS/Physical Model/WHY/PM_Enhance Marketing and Presentations-58.png";
import phmIcon5 from "../../../assets/ICONS/Physical Model/WHY/PM_Expertise and Experience.png";
import phmIcon6 from "../../../assets/ICONS/Physical Model/WHY/PM_Tangible Visualization.png";
import arIcon1 from "../../../assets/ICONS/AR/WHY/AR_Differentiation and Competitive Edge.png";
import arIcon2 from "../../../assets/ICONS/AR/WHY/AR_Enhanced Marketing Materials.png";
import arIcon3 from "../../../assets/ICONS/AR/WHY/AR_Forward-Thinking and Innovative Approach.png";
import arIcon4 from "../../../assets/ICONS/AR/WHY/AR_Immersive Visualization-34.png";
import arIcon5 from "../../../assets/ICONS/AR/WHY/AR_Modernize Existing Models.png";
import arIcon6 from "../../../assets/ICONS/AR/WHY/AR_Versatility and Flexibility.png";
export const arr13 = [
  {
    title: "ORS Convenience",
    id: 1,
    slide: `Convenience: With ORS, buyers can explore properties
    from the comfort of their own homes, on their own time.
    No more scheduling appointments, rushing to open houses,
    or dealing with crowds - ORS offers the ultimate in
    convenience.`,
    icon: orsIcon1,
  },
  {
    title: "ORS effeciency",
    id: 2,
    slide: ` Efficiency: ORS allows buyers to quickly and easily find
    the properties that meet their needs, without having to
    sift through countless listings or visit multiple
    locations. This saves both time and effort, allowing
    buyers to make more informed decisions faster.`,
    icon: orsIcon4,
  },
  {
    title: "ORS personalization",
    id: 3,
    slide: `Personalization: ORS offers a highly customizable
    experience, allowing buyers to view properties from a
    variety of angles and perspectives, zoom in on specific
    details, and even customize finishes and furnishings to
    suit their preferences.`,
    icon: orsIcon5,
  },
  {
    title: "ORS Transparency",
    id: 4,
    slide: `Transparency: With ORS, buyers have access to all the
    information they need to make an informed decision, from
    unit availability and pricing to floor plans and more.
    This transparency builds trust and confidence in the
    buying process.`,
    icon: orsIcon3,
  },
  {
    title: "ORS Reach potential clients",
    id: 5,
    slide: `Reach potential clients anywhere, anytime`,
    icon: orsIcon2,
  },
];

export const arr14 = [
  {
    title: "Immersive Visualization",
    id: 1,
    slide: `immersive Visualization: Our Interactive Model service
    allows potential clients to immerse themselves in a
    realistic representation of the property or development. By
    combining a physical architectural model with interactive
    elements, they can visualize the project in a captivating
    and engaging manner.`,
    icon: ipmIcon4,
  },
  {
    title: "Interactive Exploration",
    id: 2,
    slide: `Interactive Exploration: Potential clients can actively
    interact with the model, exploring different units, areas,
    and features. This hands-on approach provides a deeper
    understanding of the property, enabling them to make
    informed decisions.`,
    icon: ipmIcon5,
  },
  {
    title: "Enhanced Engagement",
    id: 3,
    slide: `Enhanced Engagement: The combination of a physical model and
    interactive elements creates a unique and memorable
    experience. Potential clients are drawn in by the tactile
    nature of the model and captivated by the interactive
    features, leading to increased engagement and interest.`,
    icon: ipmIcon3,
  },
  {
    title: "Customized Information",
    id: 4,
    slide: `Customized Information: Through interactive elements,
    potential clients can access customized information about
    each unit or area of the property. They can view floor
    plans, renderings, and other relevant details, empowering
    them to evaluate the property based on their specific needs
    and preferences.`,
    icon: ipmIcon1,
  },
  {
    title: "Differentiation in the Market",
    id: 5,
    slide: `Differentiation in the Market: By offering an Interactive
    Model service, you set yourself apart from competitors who
    rely solely on traditional marketing methods. This
    innovative approach demonstrates your commitment to
    providing immersive experiences and showcases your property
    in a way that stands out in the market.`,
    icon: ipmIcon2,
  },
  {
    title: "Memorable Impressions",
    id: 6,
    slide: `Memorable Impressions: The unique combination of physical
    and interactive elements leaves a lasting impression on
    potential clients. The interactive nature of the model
    creates a memorable experience that enhances their
    connection to the property and increases the likelihood of
    conversion.`,
    icon: ipmIcon6,
  },
];

export const arr15 = [
  {
    title: "Customized Solutions",
    id: 1,
    slide: `Customized Solutions for Your Vision: We understand that
    each project is unique. Our team works closely with you to
    create tailor-made interactive solutions that align with
    your branding, vision, and target audience, ensuring a
    personalized and impactful experience.`,
    icon: ibsIcon2,
  },
  {
    title: "Enhanced Property Showcasing",
    id: 2,
    slide: `Enhanced Property Showcasing: With our IBS service, you can
    effectively showcase your properties in a visually stunning
    and interactive manner. Potential buyers can explore every
    detail, visualizing themselves in the space and generating a
    strong emotional connection.`,
    icon: ibsIcon3,
  },
  {
    title: "Competitive Edge in the Market",
    id: 3,
    slide: `Competitive Edge in the Market: By leveraging our innovative
    technology, you gain a competitive advantage in the real
    estate market. Stand out from the crowd, impress potential
    buyers, and increase your chances of driving sales and
    conversions.`,
    icon: ibsIcon1,
  },
  {
    title: "Seamless User Experience",
    id: 4,
    slide: `Seamless User Experience: Our user-friendly interfaces and
    intuitive gestures provide a seamless and enjoyable
    experience for potential buyers. They can easily navigate
    through the interactive content, making it effortless to
    explore and understand the unique features of your
    properties.`,
    icon: ibsIcon5,
  },
  {
    title: "High-Quality 3D Visualizations",
    id: 5,
    slide: `High-Quality 3D Visualizations: Our expertise in developing
    realistic and immersive 3D files ensures that your
    properties are represented with the utmost accuracy and
    attention to detail. These high-quality visualizations
    create a strong visual impact and leave a lasting impression
    on potential buyers.`,
    icon: ibsIcon4,
  },
];

export const arr16 = [
  {
    title: "Immersive Visualizations",
    id: 1,
    slide: `Immersive Visualizations: Our AR technology brings physical models to life, providing clients with immersive 3D visualizations. This interactive experience allows potential buyers to explore and engage with the virtual representation of the model, fostering a deeper understanding of the project.`,
    icon: arIcon4,
  },
  {
    title: "Modernize Existing Models",
    id: 2,
    slide: `Modernize Existing Models: With our AR for Models service, clients can revitalize their existing models. By overlaying digital elements onto physical models, we give them a fresh and contemporary appeal, captivating potential buyers and making a lasting impression.`,
    icon: arIcon5,
  },
  {
    title: "Enhanced Marketing Materials",
    id: 3,
    slide: `Enhanced Marketing Materials: Our AR technology goes beyond models. Clients can target brochures or documents, enriching them with interactive and immersive 3D visualizations. This innovative approach elevates marketing materials, capturing the attention of potential buyers and setting the project apart from the competition.`,
    icon: arIcon2,
  },
  {
    title: "Differentiation and Competitive Edge",
    id: 4,
    slide: `Differentiation and Competitive Edge: By incorporating AR into their presentations, clients gain a competitive advantage. The interactive and engaging nature of AR sets their projects apart, leaving a memorable impression on potential buyers and helping to differentiate their offerings in a crowded market.`,
    icon: arIcon1,
  },
  {
    title: "Versatility and Flexibility",
    id: 5,
    slide: `Versatility and Flexibility: Our AR for Models service offers versatility and flexibility. It can be applied to both existing and new models, allowing clients to showcase their projects at various stages of development. Additionally, the ability to target brochures or documents extends the reach of the service, providing additional marketing opportunities.`,
    icon: arIcon6,
  },
  {
    title: "Forward-Thinking and Innovative Approach",
    id: 6,
    slide: `Forward-Thinking and Innovative Approach: Choosing our AR for Models service demonstrates a forward-thinking and innovative approach to real estate marketing. By embracing cutting-edge technology, clients show their commitment to providing engaging and immersive experiences to potential buyers, positioning themselves as industry leaders.`,
    icon: arIcon3,
  },
];

export const arr17 = [
  {
    title: "Expertise and Experience",
    id: 1,
    slide: `Expertise and Experience: With years of experience in the field, we bring a wealth of expertise and knowledge to every project. Our team understands the intricacies of physical model production, ensuring high-quality and accurate representations.`,
    icon: phmIcon5,
  },
  {
    title: "Attention to Detail",
    id: 2,
    slide: `Attention to Detail: We pride ourselves on our meticulous attention to detail. From the textures and materials to the intricate elements, our physical models are crafted with precision, capturing the essence of your architectural vision.`,
    icon: phmIcon1,
  },
  {
    title: "Tangible Visualization",
    id: 3,
    slide: `Tangible Visualization: Physical models provide a tangible and tactile experience for clients and stakeholders. By showcasing your project in a physical form, you create a deeper connection and enhance their understanding and appreciation.`,
    icon: phmIcon6,
  },
  {
    title: "Customization and Personalization",
    id: 4,
    slide: `Customization and Personalization: We understand that every project is unique. Our service offers customization and personalization options to tailor the physical model to your specific requirements, allowing you to showcase the distinct features and design elements.`,
    icon: phmIcon2,
  },
  {
    title: "Effective Communication Tool",
    id: 5,
    slide: `Effective Communication Tool: Physical models serve as powerful communication tools. They facilitate effective communication between architects, designers, clients, and stakeholders by providing a common visual reference point that enhances discussions and decision-making processes.`,
    icon: phmIcon3,
  },
  {
    title: "Enhance Marketing and Presentations",
    id: 6,
    slide: `Enhance Marketing and Presentations: Incorporating physical models into your marketing materials and presentations adds a visual centerpiece that captures attention and makes a lasting impression. The physicality of the model creates a memorable experience for potential clients and sets your projects apart from competitors.`,
    icon: phmIcon4,
  },
];
