import { Grid } from "@mui/material";
import React from "react";
import { FooterStyle } from "../style/footerStyle";
import logo from "../assets/LogoVreal.svg";
import facebook from "../assets/social media icons/facebook.png";
import instgram from "../assets/social media icons/instagram.png";
import linkedin from "../assets/social media icons/linkedin.png";
import youtube from "../assets/social media icons/youtube.png";
import { StyledLink } from "../style/NavBarStyle";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <FooterStyle>
      {/* <div className="content">
        <Grid container columns={12}>
          <Grid item md={3} xs={12}>
            <ul>
              <li>
                {" "}
                <img src={logo}></img>
              </li>
            </ul>
          </Grid>
          <Grid item md={3} xs={12}>
            <h3>Our Services</h3>
            <ul>
              <li>Online Reality Solution</li>
              <li>Interactive Based Solution</li>
              <li>Physical Model</li>
              <li>Virtual Exhebtion</li>
            </ul>
          </Grid>

          <Grid item md={3} xs={12}>
            {" "}
            <h3>Our Services</h3>
            <ul>
              <li>Online Reality Solution</li>
              <li>Interactive Based Solution</li>
              <li>Physical Model</li>
              <li>Virtual Exhebtion</li>
            </ul>
          </Grid>

          <Grid item md={3} xs={12}>
            {" "}
            <h3>Our Services</h3>
            <ul>
              <li>Online Reality Solution</li>
              <li>Interactive Based Solution</li>
              <li>Physical Model</li>
              <li>Virtual Exhebtion</li>
            </ul>
          </Grid>
        </Grid>
      </div> */}
      <Grid
        container
        columns={12}
        spacing={8}
        justifyContent="center"
        alignItems="start"
      >
        <Grid item xs={12} md={4} lg={3}>
          <ul className="firstUl">
            <li>
              <a href="https://adgrouptech.com/" target="_blank">
                <img src={logo}></img>
              </a>
            </li>
            {/* <li>
              <p>
                We’re on a mission to constantly evolve the way digital and
                integrated solutions are done so that we can enable working
                smart and not just hard.
              </p>
            </li> */}
            {/* <li>
              <a
                href="https://www.facebook.com/people/Smarty-Tech/100088279747899/"
                target="_blank"
              >
                {" "}
                <img src={facebook} alt="" className="imageFilter" />
              </a>
              <a href="https://www.instagram.com/smarty_edu/" target="_blank">
                {" "}
                <img src={instgram} alt="" className="imageFilter" />
              </a>
              <a href="https://www.linkedin.com/company/smarty-edu/">
                {" "}
                <img src={linkedin} alt="" className="imageFilter" />
              </a>{" "}
              <a href="https://www.youtube.com/@smartyedu" target="_blank">
                <img src={youtube} alt="" className="imageFilter" />
              </a>
            </li> */}
          </ul>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <h3>Our Services</h3>
          <ul>
            <Link to="/single-service/13/about">
              <li>ORS</li>
            </Link>
            <Link to="/single-service/14/about">
              <li>Interactive Physical Model</li>
            </Link>
            <Link to="/single-service/15/about">
              <li>IBS</li>
            </Link>
            <Link to="/single-service/16/about">
              <li>AR</li>
            </Link>{" "}
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{ paddingLeft: "120px" }}>
          <h3>Quick Links</h3>
          <ul>
            <StyledLink to="/#aboutVreal" smooth>
              <li>About Vreal</li>
            </StyledLink>
            <StyledLink to="/#why" smooth>
              <li>Why Vreal</li>
            </StyledLink>
            <StyledLink to="/#services" smooth>
              <li>Our Services</li>
            </StyledLink>
            <StyledLink to="#contact" smooth>
              <li>Get in touch</li>
            </StyledLink>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <h3>Our Social Media</h3>

          <ul>
            <li>Facebook</li>
            <li>LinkedIn</li>
            <li>Twitter</li>
            <li>Instagram</li>
          </ul>
        </Grid>
      </Grid>
    </FooterStyle>
  );
};

export default Footer;
