import styled from "styled-components";
import { SingleServiceStyle } from "./singleServiceStyle";
import { VrealStyle } from "./whyVreal";

export const PhysicalModalStyle = styled(SingleServiceStyle)`
  && {
    padding: 2rem;
    .singlePhysical {
      width: auto;
      max-width: 500px;
      height: auto;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        width: 100%;
        height: 100%;
        margin-bottom: -5px;
        transition: all 0.3s ease-out;
        position: relative;
        z-index: 2;
        overflow: hidden;
      }
      p {
        padding: 1.5rem 0.5rem;
        font-size: 25px;
        font-weight: 700;
        overflow: hidden;
      }
      &:hover img {
        transform: scale(1.2, 1.2);
      }
    }
    .pageContent {
      p {
        padding: 2rem 0;
        font-size: 15px;
        max-width: 85%;
      }
      h3 {
        font-size: 25px;
        letter-spacing: 2px;
        font-weight: 800;
      }
    }
    .nav {
      padding: 0;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
`;
