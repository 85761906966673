import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`
from{
  transform:  rotate(0deg)
 }
 to {
  transform: rotate(360deg)   
 }
`;
export const PeoplesStyle = styled.div`
  color: #ffffff;
  position: relative;
  padding: 0 1rem;
  padding-top: 10rem;
  overflow: hidden;
  background-color: black;
  z-index: 1;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  min-height: 100vh;
  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  .container div {
    position: absolute;
    animation: ${breatheAnimation} 15s linear infinite;
    animation-duration: 20s;
  }
  .container div:nth-child(3) {
    height: 90%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    bottom: 0;
    border-radius: 50px;
  }
  .container div:nth-child(4) {
    height: 80%;
    width: 70%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    top: 5%;
    right: -5%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .container div:nth-child(2) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    top: 0%;
    animation-delay: 15s;
    border-radius: 50px;
  }
  .container div:nth-child(1) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    bottom: -15%;
    right: -20%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .container:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
  }
  .card {
    text-align: center;
    .content {
      border: 0.6px solid #ffffff;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        opacity: 0.8;
        width: 60%;
        text-align: center;
        margin: auto;
      }
      padding-top: 2rem;
      padding-bottom: 4rem;
    }
    .name {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      text-transform: uppercase;

      color: #ffffff;
    }
    .job {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      color: #ffffff;

      opacity: 0.7;
    }
  }
`;
