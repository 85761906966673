import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  div {
    scroll-snap-align: start;
  }
`;
