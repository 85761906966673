import { Grid } from "@mui/material";
import React from "react";
import {
  ButtonBY,
  ButtonTour,
  ButtonTourDown,
  ButtonVideo,
  HeaderStyle,
  style,
} from "../style/HeaderStyle";
import NavBar from "./NavBar";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import donwicon from "../assets/downIcon.svg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
const Header = ({ scrolTo, ref, goToSectionRef }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("goToSectionRef", goToSectionRef);
  return (
    <HeaderStyle id="home">
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="flexcontainer">
        <div className="content">
          <p className="title">VREAL</p>
          <p className="descrp">
            {" "}
            We serve real estate industy with new technologies and unique
            services
          </p>
        </div>
      </div>
      <div className="videobutton">
        <ButtonVideo
          onClick={handleOpen}
          startIcon={
            <PlayCircleOutlineIcon
              style={{ fontSize: "2rem" }}
            ></PlayCircleOutlineIcon>
          }
        >
          Watch video
        </ButtonVideo>
      </div>
      <div className="lastSection">
        <ul>
          <li>
            <ButtonTour>Take a tour</ButtonTour>
          </li>
          <li>
            <ButtonTourDown
              onClick={() => {
                scrolTo(goToSectionRef);
                // alert("asdsa")
                console.log("Clicked");
              }}
            >
              <img src={donwicon} alt="" />
            </ButtonTourDown>
          </li>
          <li>{/* <ButtonBY>By: ADGroup Company</ButtonBY> */}</li>
        </ul>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a Video
          </Typography>
        </Box>
      </Modal>
    </HeaderStyle>
  );
};

export default Header;
