import orsIcon1 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_Availability check.png";
import orsIcon2 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_Reservation.png";
import orsIcon3 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_Completion.png";
import orsIcon4 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_Unit selection.png";
import orsIcon5 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_our team study.png";
import orsIcon6 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_share the link everywhere.png";
import orsIcon7 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_solution in a simple link.png";
import orsIcon8 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_virtual tour.png";
import orsIcon9 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_property creation.png";
import orsIcon10 from "../../../assets/ICONS/ORS/HOW_IT_WORKS/ORS_project files.png";
import ibsIcon1 from "../../../assets/ICONS/IBS/HOW IT WORKS/IBS_3D File Development.png";
import ibsIcon2 from "../../../assets/ICONS/IBS/HOW IT WORKS/IBS_Customization Dashboard.png";
import ibsIcon3 from "../../../assets/ICONS/IBS/HOW IT WORKS/IBS_Engaging and Informative.png";
import ibsIcon4 from "../../../assets/ICONS/IBS/HOW IT WORKS/IBS_Expert Guidance and Support.png";
import ibsIcon5 from "../../../assets/ICONS/IBS/HOW IT WORKS/IBS_Initial Consultation.png";
import ibsIcon6 from "../../../assets/ICONS/IBS/HOW IT WORKS/IBS_Interactive Touch Screen.png";
import ibsIcon7 from "../../../assets/ICONS/IBS/HOW IT WORKS/IBS_User-Friendly Interface.png";
import ipmIcon1 from "../../../assets/ICONS/Interactive Physical Model/HOW IT WORKS/IPM_Customized Information copy.png";
import ipmIcon2 from "../../../assets/ICONS/Interactive Physical Model/HOW IT WORKS/IPM_Differentiation in the Market copy.png";
import ipmIcon3 from "../../../assets/ICONS/Interactive Physical Model/HOW IT WORKS/IPM_Enhanced Engagement copy.png";
import ipmIcon4 from "../../../assets/ICONS/Interactive Physical Model/HOW IT WORKS/IPM_Immersive Visualization copy 2.png";
import ipmIcon5 from "../../../assets/ICONS/Interactive Physical Model/HOW IT WORKS/IPM_Interactive Exploration copy.png";
import ipmIcon6 from "../../../assets/ICONS/Interactive Physical Model/HOW IT WORKS/IPM_Memorable Impressions copy.png";
import phmIcon1 from "../../../assets/ICONS/Physical Model/HOW IT WORKS/PM_Concept Development.png";
import phmIcon2 from "../../../assets/ICONS/Physical Model/HOW IT WORKS/PM_Delivery and Display.png";
import phmIcon3 from "../../../assets/ICONS/Physical Model/HOW IT WORKS/PM_Enhance Marketing and Presentations-59.png";
import phmIcon4 from "../../../assets/ICONS/Physical Model/HOW IT WORKS/PM_Final Presentation.png";
import phmIcon5 from "../../../assets/ICONS/Physical Model/HOW IT WORKS/PM_Iterative Feedback.png";
import phmIcon6 from "../../../assets/ICONS/Physical Model/HOW IT WORKS/PM_Precision Craftsmanship.png";
import arIcon1 from "../../../assets/ICONS/AR/HOW IT WORKS/AR_AR App Installation.png";
import arIcon2 from "../../../assets/ICONS/AR/HOW IT WORKS/AR_Customization and Options.png";
import arIcon3 from "../../../assets/ICONS/AR/HOW IT WORKS/AR_Interactive Exploration-43.png";
import arIcon4 from "../../../assets/ICONS/AR/HOW IT WORKS/AR_Model Scanning.png";
import arIcon5 from "../../../assets/ICONS/AR/HOW IT WORKS/AR_Seamless Integration.png";
import arIcon6 from "../../../assets/ICONS/AR/HOW IT WORKS/AR_Sharing and Collaboration.png";
import arIcon7 from "../../../assets/ICONS/AR/HOW IT WORKS/AR_Targeting the Model.png";
export const arr13 = [
  {
    title: "ORS project files",
    id: 1,
    slide: "You send us your project files (PDF, 3D files)",
    icon: orsIcon10,
  },
  {
    title: "ORS our team study",
    id: 2,
    slide: "Our Team study it and send you a competitive quotation.",
    icon: orsIcon5,
  },
  {
    title: "ORS solution in a simple link",
    id: 3,
    slide:
      "You will get your solution in a simple link with a dashboard to control your units",
    icon: orsIcon7,
  },
  {
    title: "ORS share the link everywhere",
    id: 4,
    slide:
      "Share the link everywhere to get the best qualified leads with approx. free cost",
    icon: orsIcon6,
  },
  {
    title: "ORS property creation",
    id: 5,
    slide:
      "Property Creation: Our team creates a digital replica of your property, including all the necessary features and details",
    icon: orsIcon9,
  },
  {
    title: "ORS virtual tour",
    id: 6,
    slide:
      "Virtual Tour: Clients can take a virtual tour of the property, exploring every corner and experiencing the property as if theywere there in person.",
    icon: orsIcon8,
  },
  {
    title: "ORS Unit selection",
    id: 7,
    slide:
      "Unit Selection: Clients can select the units they want based on their preferences, including unit type, location, view, and other customizable features.",
    icon: orsIcon4,
  },
  {
    title: "ORS Availability check",
    id: 8,
    slide:
      "Availability Check: Clients can check the availability of the selected units in real-time and receive immediate feedback.",
    icon: orsIcon1,
  },
  {
    title: "ORS Reservation",
    id: 9,
    slide:
      "Reservation: Clients can reserve the units they want online,without having to visit the property physically",
    icon: orsIcon2,
  },
  {
    title: "ORS Completion",
    id: 10,
    slide:
      "Completion: The reservation process is completed, and clients can proceed to the next step of purchasing the property.",
    icon: orsIcon3,
  },
];

export const arr14 = [
  {
    title: "Immersive Visualization",
    id: 1,
    slide: `Immersive Visualization: Experience a realistic representation
    of the property, combining physical models with interactive
    elements for captivating visual immersion.`,
    icon: ipmIcon4,
  },
  {
    title: "Interactive Exploration",
    id: 2,
    slide: `Interactive Exploration: Engage actively with the model,
    exploring different units and features, enabling informed
    decision-making.`,
    icon: ipmIcon5,
  },
  {
    title: "Enhanced Engagement",
    id: 3,
    slide: `Enhanced Engagement: The combination of physical and interactive
    elements creates a memorable experience, increasing client
    engagement and interest.`,
    icon: ipmIcon3,
  },
  {
    title: "Customized Information",
    id: 4,
    slide: `Customized Information: Access tailored details for each unit or
    area through interactive elements, empowering clients to
    evaluate based on their needs.`,
    icon: ipmIcon1,
  },
  {
    title: "Market Differentiation",
    id: 5,
    slide: `Market Differentiation: Stand out from competitors by offering
    innovative Interactive Model service, showcasing your property
    uniquely.`,
    icon: ipmIcon2,
  },
  {
    title: "Memorable Impressions",
    id: 6,
    slide: `Memorable Impressions: Create lasting impact through the
    interactive nature of the model, enhancing client connection and
    conversion potential.`,
    icon: ipmIcon6,
  },
];

export const arr15 = [
  {
    title: "Initial Consultation",
    id: 1,
    slide: `Initial Consultation: We start by understanding your unique
    requirements, goals, and vision for the project during an
    initial consultation. This helps us tailor our IBS service
    precisely to your needs.`,
    icon: ibsIcon5,
  },
  {
    title: "3D File Development",
    id: 2,
    slide: `3D File Development: Our skilled professionals transform your
    architectural plans and designs into highly realistic and
    immersive 3D files. These files showcase your properties' unique
    features, giving potential buyers an accurate representation.`,
    icon: ibsIcon1,
  },
  {
    title: "Interactive Touch Screen Integration",
    id: 3,
    slide: `Interactive Touch Screen Integration: We seamlessly integrate
    the 3D files into our interactive touch screen technology.
    Placed strategically in your sales office or showroom, these
    touch screens provide a captivating platform for potential
    buyers to engage with the interactive content.`,
    icon: ibsIcon6,
  },
  {
    title: "User-Friendly Interface",
    id: 4,
    slide: `User-Friendly Interface: Our user-friendly interface ensures
    effortless navigation for potential buyers. They can explore
    different spaces, zoom in and out, and rotate the view, gaining
    a comprehensive understanding of the property's layout and
    design.`,
    icon: ibsIcon7,
  },
  {
    title: "Customization Dashboard",
    id: 5,
    slide: `Customization Dashboard: We provide a dashboard that allows
    potential buyers to customize their units. Through the
    dashboard, they can select finishes, modify floor plans, and
    visualize the personalized space to match their preferences.`,
    icon: ibsIcon2,
  },
  {
    title: "Engaging and Informative",
    id: 6,
    slide: `Engaging and Informative Experience: The interactive touch
    screen and customization dashboard create an engaging and
    informative experience for potential buyers. They can virtually
    walk through the property, explore various customization
    options, and make informed decisions about their future unit.`,
    icon: ibsIcon3,
  },
  {
    title: "Expert Guidance and Support",
    id: 7,
    slide: `Expert Guidance and Support: Throughout the process, our team
    offers expert guidance and support. We ensure that the
    interactive experience aligns with your branding and marketing
    objectives, and we provide technical assistance and answer any
    questions that arise.`,
    icon: ibsIcon4,
  },
];

export const arr16 = [
  {
    title: "Model Scanning",
    id: 1,
    slide: `Model Scanning: We start by scanning your physical model using advanced scanning technology to capture its intricate details and dimensions.`,
    icon: arIcon4,
  },
  {
    title: "AR App Installation",
    id: 2,
    slide: `AR App Installation: Clients install our AR app on their mobile or tablet devices, which serves as the platform for experiencing the augmented reality.`,
    icon: arIcon1,
  },
  {
    title: "Targeting the Model",
    id: 3,
    slide: `Targeting the Model: Using the AR app, clients can simply point their device towards the physical model. The app recognizes the model and overlays the 3D visualization onto it in real-time.`,
    icon: arIcon7,
  },
  {
    title: "Interactive Exploration",
    id: 4,
    slide: `Interactive Exploration: Once the 3D visualization is displayed on the device, clients can explore the model by moving around it, zooming in and out, and rotating the view. They can view different angles, interior spaces, and even interact with virtual elements.`,
    icon: arIcon3,
  },
  {
    title: "Customization and Options",
    id: 5,
    slide: `Customization and Options: Clients can also utilize interactive features within the app to customize certain aspects of the model, such as finishes, materials, or layout options. This allows them to visualize the possibilities and make informed decisions.`,
    icon: arIcon2,
  },
  {
    title: "Sharing and Collaboration",
    id: 6,
    slide: `Sharing and Collaboration: The AR app enables clients to easily share their augmented reality experiences with potential buyers, stakeholders, or team members. This fosters collaboration, enhances communication, and facilitates the decision-making process.`,
    icon: arIcon6,
  },
  {
    title: "Seamless Integration",
    id: 7,
    slide: `Seamless Integration: Our AR for Models service seamlessly integrates with your existing marketing materials, such as brochures or digital presentations. Clients can easily target these materials with the AR app to showcase augmented reality experiences, adding an extra layer of interactivity and engagement.`,
    icon: arIcon5,
  },
];

export const arr17 = [
  {
    title: "Project Consultation",
    id: 1,
    slide: `Project Consultation: We begin with a comprehensive consultation to understand your project requirements, design intent, and desired outcome.`,
    icon: phmIcon3,
  },
  {
    title: "Concept Development",
    id: 2,
    slide: `Concept Development: Our team of experts works closely with you to develop a concept that accurately represents your architectural vision. We collaborate on design elements, materials, and scale to ensure the model reflects your project accurately.`,
    icon: phmIcon1,
  },
  {
    title: "Precision Craftsmanship",
    id: 3,
    slide: `Precision Craftsmanship: Skilled artisans meticulously craft the physical model using advanced techniques and high-quality materials. Every detail is carefully considered and executed to ensure a precise and visually stunning representation.`,
    icon: phmIcon6,
  },
  {
    title: "Iterative Feedback",
    id: 4,
    slide: `Iterative Feedback: Throughout the process, we encourage feedback and collaboration, allowing you to review and provide input on the model's progress. This iterative approach ensures that the final physical model aligns with your expectations.`,
    icon: phmIcon5,
  },
  {
    title: "Final Presentation",
    id: 5,
    slide: `Final Presentation: Once the physical model is complete, we present it to you for final approval. We take pride in delivering a meticulously crafted model that showcases the unique aspects of your project.`,
    icon: phmIcon4,
  },
  {
    title: "Delivery and Display",
    id: 6,
    slide: `Delivery and Display: Upon your approval, we carefully package and deliver the physical model to your desired location. We can also provide guidance on how to effectively display and incorporate the model into your presentations and marketing materials.`,
    icon: phmIcon2,
  },
];
