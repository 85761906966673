import React from "react";
import AboutVreal from "../components/AboutVreal";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Services from "../components/Services";
import SuccessStories from "../components/SuccessStories";
import WhyVreal from "../components/WhyVreal";
import { Container } from "../style/HomePageStyle";
import WhatPeopleSay from "../components/WhatPeopleSay";
import { useDispatch, useSelector } from "react-redux";
import { GetAllPages } from "../Redux/Slices/PagesSlice/PageSlice";
import { GetAllServices } from "../Redux/Slices/ServicesSlice/ServicesSlice";
import Faq from "../components/Faq";
import Contact from "../components/contact";
import Footer from "../components/Footer";
const HomePage = () => {
  const dispatch = useDispatch();
  const { servicesStatus } = useSelector((state) => state?.services);
  const { pagesStatus } = useSelector((state) => state?.pages);
  React.useEffect(() => {
    let apis = async () => {
      await dispatch(GetAllPages());
      await dispatch(GetAllServices());
    };
    apis();
  }, []);
  return (
    <div>
      <Container>
        <NavBar></NavBar>
        <Header></Header>
        <AboutVreal></AboutVreal>
        <WhyVreal></WhyVreal>
        <Services></Services>
        <Faq></Faq>
        <SuccessStories></SuccessStories>
        <WhatPeopleSay />
        <Contact />
        <Footer />
      </Container>
    </div>
  );
};

export default HomePage;
