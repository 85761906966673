import { Button, Grid } from "@mui/material";
import React from "react";
import { RequestDemoStyle } from "../style/RequestDemoStyle";
import Footer from "./Footer";
import NavBar from "./NavBar";
import icon from "../assets/burgerIcon.svg";
import img from "../assets/singleServiceImg.png";
import { Mybutton, PageButton } from "../style/NavBarStyle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import video from "../assets/video.png";
import { Link, useNavigate } from "react-router-dom";
const RequestDemo = () => {
  const navigte = useNavigate();
  return (
    <RequestDemoStyle>
      {/* <div className="nav">
        <div>
          <Link to="/">
            {" "}
            <Button
              onClick={() => navigte(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          </Link>
        </div>
        <div>
          <ul>
            <li>
              <Mybutton>Request a demo</Mybutton>
            </li>
            <li>
              <PageButton
                // onClick={() => {
                //   setShowOptions(!showOptions);
                // }}
                style={{ border: "none" }}
              >
                <img src={icon}></img>
              </PageButton>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="hero">
        <Grid
          container
          columns={12}
          spacing={10}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item xs={12} md={6}>
            <h3>Get a free Demo</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque
              aliquet a gravida tristique phasellus nunc sit sit. Risus habitant
              nullam dignissim vulputate tincidunt. Eget gravida pretium, elit
              aliquet euismod purus varius in. Sit eget ut posuere sed donec
              orci nunc. Pharetra sed erat elit sagittis.
            </p>
            <img src={video}></img>
          </Grid>
          <Grid item xs={12} md={6} className="customeForm">
            {/* <form> */}
            <input placeholder="Name"></input>
            <input placeholder="Email"></input>
            <input placeholder="PHO"></input>
            <textarea placeholder="Message"></textarea>
            <button>Send</button>
            {/* </form> */}
          </Grid>
        </Grid>
      </div>
      {/* <Footer></Footer> */}
    </RequestDemoStyle>
  );
};

export default RequestDemo;
