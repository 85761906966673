import React from "react";
import { ServiceChildComponent } from "../../style/servicesComponentStyle/serviceComponetnsStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Pagination, Navigation } from "swiper";
import icon from "../../assets/image2.png";
const VirtualExhibtion = () => {
  return (
    <ServiceChildComponent>
      {" "}
      <div className="qualities">
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
          className="slide"
          breakpoints={{
            1920: { slidesPerView: 4, spaceBetween: 20 },
            1440: { slidesPerView: 4, spaceBetween: 20 },
            1257: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            639: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            418: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide>
            {" "}
            <div className="wrapper">
              <div className="wrapperContnet">
                {/* <div className="thumb"> */}
                <img src={icon}></img>
                {/* </div> */}
              </div>
              <div className="hiddencontent">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ducimus consequuntur eos nisi consectetur ea dolorum ipsam
                  vitae, earum, libero accusamus eveniet, odio enim sint magni
                  quia rem vel beatae quae! Lorem ipsum dolor sit amet
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div className="wrapper">
              <div className="wrapperContnet">
                {/* <div className="thumb"> */}
                <img src={icon}></img>
                {/* </div> */}
              </div>
              <div className="hiddencontent">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ducimus consequuntur eos nisi consectetur ea dolorum ipsam
                  vitae, earum, libero accusamus eveniet, odio enim sint magni
                  quia rem vel beatae quae! Lorem ipsum dolor sit amet
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div className="wrapper">
              <div className="wrapperContnet">
                {/* <div className="thumb"> */}
                <img src={icon}></img>
                {/* </div> */}
              </div>
              <div className="hiddencontent">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ducimus consequuntur eos nisi consectetur ea dolorum ipsam
                  vitae, earum, libero accusamus eveniet, odio enim sint magni
                  quia rem vel beatae quae! Lorem ipsum dolor sit amet
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div className="wrapper">
              <div className="wrapperContnet">
                {/* <div className="thumb"> */}
                <img src={icon}></img>
                {/* </div> */}
              </div>
              <div className="hiddencontent">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ducimus consequuntur eos nisi consectetur ea dolorum ipsam
                  vitae, earum, libero accusamus eveniet, odio enim sint magni
                  quia rem vel beatae quae! Lorem ipsum dolor sit amet
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </ServiceChildComponent>
  );
};

export default VirtualExhibtion;
