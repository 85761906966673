import { Navigate, Route, Routes } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import RequestDemo from "./components/RequestDemo";
import IBS from "./components/servicesComponents/IBS";
import InteractiveModel from "./components/servicesComponents/InteractiveModel";
import Ios from "./components/servicesComponents/Ios";
import VirtualExhibtion from "./components/servicesComponents/VirtualExhibtion";
import SingleService from "./components/singleService/SingleService";
import HomePage from "./pages/HomePage";
import PhysicalModal from "./pages/PhysicalModal";
import SinglePysicalModal from "./pages/SinglePysicalModal";
import SingleSuccessStory from "./pages/SingleSuccessStory";
import { GlobalStyle } from "./style/globalStyle";
import HowItWorks from "./components/singleService/howItWorks/HowItWorks";
import About from "./components/singleService/about/About";
import Why from "./components/singleService/why/Why";
import Blogs from "./pages/blogs";
import BlogData from "./pages/singleBlog/page";

function App() {
  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<HomePage></HomePage>}>
          <Route path="ibs" element={<IBS></IBS>}></Route>
          <Route
            path="InteractiveModel"
            element={<InteractiveModel></InteractiveModel>}
          ></Route>
          <Route path="ios" element={<Ios></Ios>}></Route>
          <Route
            path="virtualExhibtion"
            element={<VirtualExhibtion></VirtualExhibtion>}
          ></Route>
        </Route>
        <Route
          path="single-modal"
          element={<SinglePysicalModal></SinglePysicalModal>}
        ></Route>
        <Route path="physical-modal" element={<PhysicalModal />}></Route>
        <Route path="single-story" element={<SingleSuccessStory />}></Route>
        <Route path="single-service/:id" element={<SingleService />}>
          <Route index element={<Navigate to="about"></Navigate>}></Route>
          <Route path="about" element={<About></About>}></Route>
          <Route
            path="how-it-works"
            element={<HowItWorks></HowItWorks>}
          ></Route>
          <Route path="why" element={<Why></Why>}></Route>
          <Route
            path="request-demo/:id"
            element={<RequestDemo></RequestDemo>}
          ></Route>
        </Route>
        <Route path="blogs">
          <Route index element={<Blogs></Blogs>}></Route>
          <Route path="singleBlog/:id" element={<BlogData></BlogData>}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
