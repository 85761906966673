import React from "react";
import { FAQStyle } from "../style/FAQStyle";
import { Button, Grid } from "@mui/material";
import SingleFaq from "./SingleFaq";
import { questions } from "../Static info/questions";

const Faq = () => {
  const [open, setOpen] = React.useState(false);
  const [next, setNext] = React.useState(4);
  const handleMoreImage = () => {
    setNext(next + 3);
  };
  return (
    <FAQStyle id="questions">
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div className="containerOne">
            <p className="header">Frequently asked questions</p>
          </div>
        </Grid>
        <div className="hero-container">
          {questions?.slice(0, next)?.map((e, i) => {
            return (
              <Grid item xs={12} key={i}>
                <SingleFaq question={e.question} answer={e.answer}></SingleFaq>
              </Grid>
            );
          })}
          {next < questions?.length && (
            <Button
              sx={{
                color: "white",
                backgroundColor: "transparent",
                margin: "10px 0",
                border: "1px solid white",
                padding: "0.5rem 1rem",
              }}
              onClick={handleMoreImage}
            >
              Load more
            </Button>
          )}
        </div>
      </Grid>
    </FAQStyle>
  );
};

export default Faq;
