import React from "react";
import { Bigsection } from "./style";
const Contact = () => {
  return (
    <Bigsection id="contact">
      <div className="container">
        <div className="left">
          <div className="countries">
            <div className="firstRow">
              <div className="singleCountry">
                <h1>Egypt</h1>
                <p>2836 White Mountain</p>
                <p>CARE@ADGROUP.TECH</p>
                <p>+20 226309636</p>
              </div>
              <div className="singleCountry">
                <h1>Egypt</h1>
                <p>2836 White Mountain</p>
                <p>CARE@ADGROUP.TECH</p>
                <p>+20 226309636</p>
              </div>
              {/* <div className="singleCountry">
                <h1>Egypt</h1>
                <p>2836 White Mountain</p>
                <p>CARE@ADGROUP.TECH</p>
                <p>+20 226309636</p>
              </div> */}
              {/* <div className="singleCountry">
                <h1>Egypt</h1>
                <p>2836 White Mountain</p>
                <p>CARE@ADGROUP.TECH</p>
                <p>+20 226309636</p>
              </div> */}
            </div>
            <div className="firstRow">
              <div className="singleCountry">
                <h1>Egypt</h1>
                <p>2836 White Mountain</p>
                <p>CARE@ADGROUP.TECH</p>
                <p>+20 226309636</p>
              </div>
              <div className="singleCountry">
                <h1>United kingdom</h1>
                <p>Coming soon...</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <form>
            <input placeholder="Name" required></input>
            <input placeholder="Email" required></input>
            {/* <input placeholder="PHO"></input> */}
            <textarea placeholder="Message" minRows={10} required></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </Bigsection>
  );
};

export default Contact;
