import styled, { keyframes } from "styled-components";
import { HeaderStyle } from "./HeaderStyle";
import icon from "../assets/whyusIcon.svg";
import { Button } from "@mui/material";
const breatheAnimation = keyframes`
from{
  transform:  rotate(0deg)
 }
 to {
  transform: rotate(360deg)   
 }

`;
export const VrealStyle = styled.div`
  /* padding-top: 10rem; */
  && {
    color: #ffffff;
    justify-content: space-around;
    position: relative;
    padding: 0 1rem;
    overflow: hidden;
    background-color: black;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    .container {
      height: 100%;
      width: 100%;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      /* border-radius: 10px; */
      z-index: -1;
      /* transition: 2s ease-out; */
    }
    .container div {
      position: absolute;
      animation: ${breatheAnimation} 10s backwards infinite;
      animation-duration: 10s;
    }
    .container div:nth-child(1) {
      width: 1191px;
      height: 643px;
      left: 186px;
      bottom: 584px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
      transform: rotate(178.01deg);
      opacity: 0.7;
    }
    .container div:nth-child(2) {
      width: 1463.49px;
      height: 217.14px;
      right: -1000.19px;
      top: 399.96px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    }
    .container div:nth-child(3) {
      width: 1463.49px;
      height: 481.4px;
      left: 1080.29px;
      top: 113.11px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
      opacity: 0.6;
      transform: rotate(178.01deg);
    }
    .container div:nth-child(4) {
      width: 1000px;
      height: 200px;
      left: 20px;
      top: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    }
    .container:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      backdrop-filter: blur(75px);
    }
    .whyus {
      color: #ffffff;
      padding: 2rem 0;
      padding-top: 6rem;

      h3 {
        font-weight: 700;
        font-size: 32px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        width: 40%;
        padding-top: 2rem;
      }
    }
    .wrapper:nth-child(odd) {
      background: linear-gradient(
        146.43deg,
        rgba(12, 160, 146, 0.4) 10.91%,
        rgba(131, 200, 138, 0.4) 85.28%
      );
      border: 2px solid #ffffff;
      border-radius: 4px;
    }
    .swiper-slide:nth-child(even) {
      .wrapper {
        background: linear-gradient(
          202.01deg,
          rgba(120, 204, 208, 0.4) 7.26%,
          rgba(0, 90, 169, 0.4) 95.99%
        );
        border: 2px solid #ffffff;
        border-radius: 4px;
      }
    }
    .qualities {
      padding: 3rem 0;
      display: flex;
      flex-wrap: wrap;
      transition: all 0.5s;
      .hiddencontent {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: none;
        width: 100%;
      }
      .wrapper {
        transition: 0.5s all;
        width: 100%;
        height: 360px;
        position: relative;
        text-align: center;
        &:hover {
          background: none;
          color: black;
          background-color: white;
          .hiddencontent {
            display: block;
            z-index: 999;
            color: black;
            text-align: center;
            margin: auto;
            p {
              color: black;
              padding: 0 3rem;
            }
          }
        }
      }
      .wrapperContnet {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
        }
        .thumb {
          height: 70px;
          width: 70px;
          border: 4px solid white;
          border-radius: 50%;
          padding: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 80%;
          object-fit: cover;
        }
      }
    }
    .lorem {
      width: 50%;
    }
    .p_and_buttons_content {
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      p {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
      }
    }
    .secondpart {
      background-color: black;
    }
    .lastSection {
      padding-top: 2rem;
      display: flex;
      justify-content: space-around;
      div:nth-child(1) {
        width: 50%;
        h3 {
          font-size: 24px;
          line-height: 24px;
          text-transform: uppercase;
        }
        p {
          /* width: 60%; */
          padding: 2rem 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
      }
      div:nth-child(2) {
        width: 50%;
        padding-left: 35px;
      }
    }
  }
`;
export const IconButton = styled(Button)`
  && {
    font-weight: 700;
    color: #ffffff;
    .icon {
      font-size: 32px;
    }
  }
`;
