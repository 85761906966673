import styled from "styled-components";

export const RequestDemoStyle = styled.div`
  /* background-color: black; */
  min-height: 100vh;
  /* padding: 0 !important; */
  /* margin: 0 !important; */
  color: white;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    button {
      color: white;
    }
    &:nth-child(1) {
      ul {
        display: flex;
        align-items: center;
        list-style: none;
      }
    }
  }
  .hero {
    padding: 2rem;
    input,
    textarea {
      width: 100%;
      background: #161616;
      border-radius: 2px 2px 0px 0px;
      color: white;
      margin-bottom: 1rem;
      padding: 20px;
      &:placeholder {
        color: white;
      }
    }
    button {
      background: #d9d9d9;
      border-radius: 4px;
      width: 100%;
      text-transform: uppercase;
      color: #000000;
      font-weight: 700;
      font-size: 16px;
      padding: 15px 0;
      cursor: pointer;
    }
    img {
      width: 100%;
    }
    h3 {
      font-weight: 700;
      font-size: 24px;
      text-transform: uppercase;
      color: #ffffff;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      padding: 4rem 0;
    }
  }
  .customeForm {
    input,
    select,
    textarea {
      color: white !important;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
    }
  }
`;
