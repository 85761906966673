import styled, { keyframes } from "styled-components";
import { PhysicalModalStyle } from "./PhysicalModalStyle";

const slideInRight = keyframes`
 0%{
  transform: rotate(178.01deg)  
 }
 50% {
  transform: rotate(178.01deg)   
 }

`;
const rollAnimation = keyframes`
    0% {
      transform: translateX(-1000px) rotate(-720deg);
      filter: blur(50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0) rotate(0deg);
      filter: blur(0);
      opacity: 1;
    }
  `;

export const SinglePysicalModalStyle = styled(PhysicalModalStyle)`
  .slide-in-right {
    animation: ${slideInRight} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .roll-in-blurred-left {
    animation: ${rollAnimation} 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  }
  .grid-container {
    display: grid;
    justify-content: stretch;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 10px;
    img {
      width: 100%;
    }
    div:nth-child(1) {
      grid-row-start: 1;
      grid-row-end: span 2;
      grid-column-start: 1;
      grid-column-end: 3;
      img {
        width: 100%;
      }
    }
    div {
      width: 100%;
      cursor: pointer;
    }
  }
  .hero{
    padding: 3rem 0;
    p{
        padding: 2rem 0;
        width: 80%;
    }
  }
`;
