import { Button, Grid } from "@mui/material";
import React from "react";
import { SingleServiceStyle } from "../../style/singleServiceStyle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import icon from "../../assets/burgerIcon.svg";
import img from "../../assets/singleServiceImg.png";
import imgSingle from "../../assets/singleServiceImage.png";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation, Autoplay } from "swiper";
import serviceImg from "../../assets/serviceTwopng.png";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleService } from "../../Redux/Slices/ServicesSlice/ServicesSlice";
import Footer from "../Footer";
import { Mybutton, PageButton } from "../../style/NavBarStyle";
import DynamicHero from "./DynamicHero";
import { NavLink } from "react-router-dom";

const SingleService = ({ setShowService, showService }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleService } = useSelector((state) => state?.services);
  console.log("singleService", singleService);
  React.useEffect(() => {
    dispatch(GetSingleService(id));
  }, [id]);
  return (
    <SlidingPane isOpen={true} from="left">
      <SingleServiceStyle>
        <div class="container">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav">
          <div>
            <Button
              onClick={() => navigate("/")}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          </div>
          <div>
            <ul>
              <li>
                <NavLink to="about"> About</NavLink>
              </li>
              <li>
                {" "}
                <NavLink to="why">why</NavLink>
              </li>
              <li>
                <NavLink to="how-it-works">how it works</NavLink>
              </li>
              <li>
                <NavLink to={`request-demo/${id}`}> Request a Demo</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <h1 style={{ textAlign: "center" }}>{singleService?.title} </h1>

        <div className="firstSection">
          <Grid container columns={12} mb={5}>
            <Grid item xs={12}>
              <div>
                <Outlet></Outlet>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <Grid container columns={12} spacing={9} className="photos">
          <Grid item xs={12} md={6} lg={4}>
            <img src={imgSingle}></img>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <img src={imgSingle}></img>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <img src={imgSingle}></img>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <img src={imgSingle}></img>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <img src={imgSingle}></img>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <img src={imgSingle}></img>
          </Grid>

          <Grid item xs={12}>
            <section id="slideshow">
              <div className="entire-content">
                <div className="content-carrousel">
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                  <figure className="shadow">
                    <img src={serviceImg} />
                  </figure>
                </div>
              </div>
            </section>
          </Grid>
        </Grid> */}
        <Footer></Footer>
      </SingleServiceStyle>
    </SlidingPane>
  );
};

export default SingleService;
