import { Button, Grid } from "@mui/material";
import React from "react";
import { Mybutton, PageButton } from "../style/NavBarStyle";
import { PhysicalModalStyle } from "../style/PhysicalModalStyle";
import icon from "../assets/burgerIcon.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import img from "../assets/gameBoy.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PhysicalModal = () => {
  const navigate = useNavigate();
  return (
    <PhysicalModalStyle>
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="nav">
        <div>
          <Button onClick={() => navigate(-1)} startIcon={<ArrowBackIosIcon />}>
            Back
          </Button>
        </div>
        <div>
          <ul>
            <li>
              <Mybutton>Request a demo</Mybutton>
            </li>
            <li>
              <PageButton
                // onClick={() => {
                //   setShowOptions(!showOptions);
                // }}
                style={{ border: "none" }}
              >
                <img src={icon}></img>
              </PageButton>
            </li>
          </ul>
        </div>
      </div>
      <div className="pageContent">
        <h3>Physical Model</h3>
        <p className="desc">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint
          exercitationem provident laborum harum aperiam deserunt rerum
          quibusdam fugit quasi neque quo libero mollitia facere, recusandae,
          magnam id sapiente nulla atque.
        </p>
      </div>
      <div className="hero">
        <Grid container columns={12} spacing={8}>
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/single-modal">
              <div className="singlePhysical">
                <img src={img}></img>
                <p> name</p>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/single-modal">
              <div className="singlePhysical">
                <img src={img}></img>
                <p> name</p>
              </div>
            </Link>
          </Grid>{" "}
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/single-modal">
              <div className="singlePhysical">
                <img src={img}></img>
                <p> name</p>
              </div>
            </Link>
          </Grid>{" "}
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/single-modal">
              <div className="singlePhysical">
                <img src={img}></img>
                <p> name</p>
              </div>
            </Link>
          </Grid>{" "}
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/single-modal">
              <div className="singlePhysical">
                <img src={img}></img>
                <p> name</p>
              </div>
            </Link>
          </Grid>{" "}
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/single-modal">
              <div className="singlePhysical">
                <img src={img}></img>
                <p> name</p>
              </div>
            </Link>
          </Grid>{" "}
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/single-modal">
              <div className="singlePhysical">
                <img src={img}></img>
                <p> name</p>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div>
    </PhysicalModalStyle>
  );
};

export default PhysicalModal;
