export const questions = [
  {
    question: "What services does your company offer",
    answer:
      "We offer a range of innovative services for real estate developers, including Interactive Based Solutions (IBS), Physical Models, and AR for Models. These services are designed to enhance marketing, presentations, and visualization for architectural projects.",
  },
  {
    question: "How can ORS benefit real estate professionals",
    answer:
      "ORS offers several benefits, including reaching a wider audience by providing virtual property tours, enhancing marketing efforts by creating engaging digital experiences, and saving time and resources by reducing the need for physical property visits.",
  },
  {
    question: "How can Interactive Based Solutions (IBS) benefit my project",
    answer: `IBS utilizes interactive touch screen technology to create engaging and immersive experiences for potential buyers. It allows for interactive exploration of floor plans, finishes, and virtual tours, providing a powerful tool for showcasing and customizing units.`,
  },
  {
    question:
      "How can your company help me implement VR and AR technology in my classroom ",
    answer:
      " Our company specializes in transferring normal school curriculum into metaverse content. We provide customized solutions tailored to the needs and challenges of your school and offer ongoing support and resources to ensure successful implementation",
  },
  {
    question: "What makes your Physical Models stand out",
    answer:
      "Our Physical Models are crafted with meticulous attention to detail and precision. We combine advanced technology and traditional craftsmanship to create visually stunning models that accurately represent your architectural vision",
  },
  {
    question: "How does AR for Models work",
    answer:
      "AR for Models utilizes augmented reality technology to bring physical models to life on mobile devices or tablets. By targeting the physical model or even brochures/documents, clients can visualize 3D representations, explore details, and customize finishes.",
  },
  {
    question: "Can I customize my unit using your services",
    answer:
      "Yes, we provide a user-friendly dashboard that allows clients to customize their units with different finishes, layouts, and design options. This empowers buyers to personalize their spaces according to their preferences and needs",
  },
  {
    question: "How can your services help in marketing my projects",
    answer:
      "Our services offer unique and captivating visual experiences that make your projects stand out. From interactive presentations to physical models and AR technology, they enhance engagement, facilitate better communication, and leave a lasting impression on potential buyers and stakeholders",
  },
  {
    question: "How can I get started with your services",
    answer:
      "Simply reach out to us through our website or contact our team directly. We'll be happy to discuss your specific needs, provide detailed information, and guide you through the process of utilizing our services for your projects",
  },
  {
    question: "Can I see examples of your previous work",
    answer:
      "Absolutely! We have a portfolio of past projects that showcase the quality and diversity of our services. We can provide you with examples relevant to your specific needs, allowing you to see the level of craftsmanship and attention to detail we deliver.",
  },
  {
    question: "What is the typical turnaround time for your services",
    answer:
      "The turnaround time depends on the scope and complexity of the project. We strive to provide efficient and timely services without compromising quality. Once we understand your project requirements, we can provide you with an estimated timeline.",
  },
  {
    question: "How do I collaborate with your team during the project",
    answer:
      "Collaboration is essential to ensure the best outcomes. We facilitate open communication channels throughout the project, allowing you to provide feedback, review progress, and make any necessary adjustments. We value your input and strive for a collaborative partnership.",
  },
  {
    question: "What is the cost associated with your services",
    answer:
      "The cost varies based on factors such as the type and scale of the project, customization requirements, and desired deliverables. We provide tailored quotes and work within your budget parameters. Contact us to discuss your specific project and receive a detailed cost estimate.",
  },
  {
    question: "Do you offer post-delivery support and maintenance",
    answer:
      ": Yes, we provide post-delivery support to address any issues or concerns you may have. Additionally, we offer maintenance services to ensure the longevity and optimal performance of our solutions. We are committed to providing ongoing support and ensuring your satisfaction.",
  },
];
