import styled from "styled-components";

export const FooterStyle = styled.footer`
  border-top: 1px solid white;
  /* background: rgba(255, 255, 255, 0.1); */
  background: #191919;

  .content {
    padding: 4rem 2rem;
  }
  h3 {
    padding-bottom: 0.9rem;
  }
  ul {
    list-style: none;
    li {
      padding: 0.4rem 0;
    }
  }
  /* background-color: #121212; */
  color: #cecece;
  padding: 3rem 2rem;
  ul {
    list-style: none;
    li {
      color: white;
      overflow: hidden;
      padding: 0.5rem 0;
      svg {
        margin-right: 15px;
        font-size: 36px;
        transition: 0.3s ease-in;
        &:hover {
          transform: scale(1.2);
          cursor: pointer;
        }
      }
      img {
        width: 50%;
      }
      p {
        width: 90%;
      }
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .firstUl {
    /* padding-top: 30px; */
  }
  .padding {
    li {
      padding: 1rem 0;
    }
  }
  .location {
    width: 100%;
  }
  h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    font-family: Cairo;
  }
  .imageFilter {
    max-width: 11%;
    filter: grayscale(100%);
    transition: all 0.3s;
    margin-right: 15px;
    &:hover {
      filter: none;
      scale: 1.1;
    }
  }
  a {
    text-decoration: none;
  }
`;
