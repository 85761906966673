import styled from "styled-components";
import { VrealStyle } from "./whyVreal";

export const BigSection = styled(VrealStyle)`
  && {
    padding: 0 2rem;
    padding-top: 8rem;
    min-height: 100vh;
    h3 {
      align-self: flex-start;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      color: #ffffff;
    }
    p {
      max-width: 58%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: left;
      color: #ffffff;
      padding: 2rem 0;
    }
    img {
      width: 100%;
    }
    .goDown {
      width: 50%;
    }
    @media (max-width: 469px) {
      p {
        max-width: 88%;
      }
    }
  }
`;
