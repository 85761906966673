import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import imgae from "../../assets/slider/image 1.png";
import { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ServiceChildComponent } from "../../style/servicesComponentStyle/serviceComponetnsStyle";
import "swiper/css/autoplay";
import { Autoplay } from "swiper";
import icon from "../../assets/image2.png";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SingleService from "../singleService/SingleService";
const IBS = () => {
  const [selectedService, setSelectedService] = React.useState("");
  const [showService, setShowService] = React.useState(false);

  return (
    <>
      {" "}
      <ServiceChildComponent>
        {" "}
        <div className="qualities">
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            modules={[Autoplay]}
            className="slide"
            breakpoints={{
              1920: { slidesPerView: 4, spaceBetween: 20 },
              1440: { slidesPerView: 4, spaceBetween: 20 },
              1257: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              639: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              418: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="wrapper">
                <div className="wrapperContnet">
                  <img src={icon}></img>
                </div>
                <div className="hiddencontent">
                  <p className="header">Service Name</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Accumsan, gravida velit dolor placerat risus integer platea
                    tortor hac.
                  </p>
                  <button onClick={() => setShowService(true)}>
                    Know More
                  </button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </ServiceChildComponent>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={showService}
        from="left"
      >
        <SingleService
          showService={showService}
          setShowService={setShowService}
        ></SingleService>
      </SlidingPane>
    </>
  );
};

export default IBS;
