import React from "react";
import { SinglePysicalModalStyle } from "../style/SinglePysicalModalStyle";
import { Mybutton, PageButton } from "../style/NavBarStyle";
import { PhysicalModalStyle } from "../style/PhysicalModalStyle";
import icon from "../assets/burgerIcon.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import img from "../assets/gameBoy.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
const SinglePysicalModal = () => {
  const navigate = useNavigate();

  return (
    <SinglePysicalModalStyle>
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="nav">
        <div>
          <Button onClick={() => navigate(-1)} startIcon={<ArrowBackIosIcon />}>
            Back
          </Button>
        </div>
        <div>
          <ul>
            <li>
              <Mybutton>Request a demo</Mybutton>
            </li>
            <li>
              <PageButton style={{ border: "none" }}>
                <img src={icon}></img>
              </PageButton>
            </li>
          </ul>
        </div>
      </div>
      <div className="hero">
        <h3>Physical Model Name</h3>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus qui
          vitae assumenda consequatur at dolorem, perferendis aspernatur
          explicabo nobis mollitia quas? Animi explicabo aperiam numquam iste
          at, dolore quis doloremque.
        </p>
      </div>
      <Gallery>
        <div class="grid-container">
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>{" "}
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>{" "}
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>{" "}
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>{" "}
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>{" "}
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>{" "}
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>{" "}
          <div>
            <Item original={img} thumbnail={img} width="1024" height="500">
              {({ ref, open }) => <img ref={ref} onClick={open} src={img} />}
            </Item>
          </div>
        </div>
      </Gallery>
    </SinglePysicalModalStyle>
  );
};

export default SinglePysicalModal;
