import styled from "styled-components";
import { ServiceStyle } from "./servicesStyle";

export const SingleServiceStyle = styled(ServiceStyle)`
  padding: 0;
  margin: 0;
  min-height: 100vh;
  scroll-behavior: smooth;

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    button {
      color: white;
    }
    &:nth-child(2) {
      ul {
        display: flex;
        align-items: center;
        list-style: none;
        justify-content: space-between;
        list-style: none;
        width: 100%;
        li {
          margin: 0 1rem;
          @media (max-width: 420px) {
            font-size: 12px;
          }
          a {
            color: white;
            text-decoration: none;
            transition: 0.3s all;
          }
          a.active {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)
              ),
              linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .firstSection {
    padding: 0 3rem;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
    @media (max-width: 967px) {
      /* flex-direction: column; */
      img {
        padding: 3rem 0;
        width: 100%;
      }
    }
    .lorem {
      width: 80%;
      font-weight: 400;
      font-size: 15px;
      /* padding: 1rem 0; */
    }
    button {
      font-weight: 400;
      font-size: 24px;
      text-transform: capitalize;
      background: none;
      color: white;
      border: 2px solid #ffffff;
      padding: 0.7rem 2rem;
      cursor: pointer;
      margin: 1rem 0;
    }
  }
  .secondSection {
    width: 100%;
    padding: 0 3rem;
    p {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .photos {
    padding: 2rem;
    img {
      width: 100%;
      border: 1px solid white;
    }
  }
  .swiper {
    width: 400px;
    /* height: 300px; */
    padding: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    /* width: 300px; */
    /* height: 250px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }

  .lorem {
    width: 80%;
    font-weight: 400;
    font-size: 14px;
    /* padding: 1rem 0; */
  }
  h1 {
    margin-bottom: 1.5rem;
  }
  h2 {
    letter-spacing: 0.2rem;
    position: relative;
    width: fit-content;
    margin-bottom: 1rem;
    &:after {
      position: absolute;
      content: "";
      bottom: -0.5rem;
      left: 0;
      width: 100%;
      height: 2px;
      background: white;
    }
  }
  ul {
    list-style: none;
    li {
      padding: 0.5rem 0;
    }
  }
  #slideshow {
    /* margin: 0 auto; */
    height: 600px;
    width: 100%;
    box-sizing: border-box;
  }

  .slideshow-title {
    font-family: "Allerta Stencil";
    font-size: 62px;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    margin-top: 25%;
    letter-spacing: 3px;
    font-weight: 300;
  }

  .sub-heading {
    padding-top: 50px;
    font-size: 18px;
  }
  .sub-heading-two {
    font-size: 15px;
  }
  .sub-heading-three {
    font-size: 13px;
  }
  .sub-heading-four {
    font-size: 11px;
  }
  .sub-heading-five {
    font-size: 9px;
  }
  .sub-heading-six {
    font-size: 7px;
  }
  .sub-heading-seven {
    font-size: 5px;
  }
  .sub-heading-eight {
    font-size: 3px;
  }
  .sub-heading-nine {
    font-size: 1px;
  }

  .entire-content {
    margin: auto;
    width: 190px;
    perspective: 1000px;
    position: relative;
    padding-top: 80px;
  }

  .content-carrousel {
    width: 100%;
    position: absolute;
    float: right;
    animation: rotar 15s infinite linear;
    transform-style: preserve-3d;
  }

  .content-carrousel:hover {
    animation-play-state: paused;
    cursor: pointer;
  }

  .content-carrousel figure {
    width: 100%;
    height: 120px;
    border: 1px solid #3b444b;
    overflow: hidden;
    position: absolute;
  }

  .content-carrousel figure:nth-child(1) {
    transform: rotateY(0deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(2) {
    transform: rotateY(40deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(3) {
    transform: rotateY(80deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(4) {
    transform: rotateY(120deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(5) {
    transform: rotateY(160deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(6) {
    transform: rotateY(200deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(7) {
    transform: rotateY(240deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(8) {
    transform: rotateY(280deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(9) {
    transform: rotateY(320deg) translateZ(300px);
  }
  .content-carrousel figure:nth-child(10) {
    transform: rotateY(360deg) translateZ(300px);
  }

  .shadow {
    position: absolute;
    box-shadow: 0px 0px 20px 0px #000;
    border-radius: 1px;
  }

  .content-carrousel img {
    image-rendering: auto;
    transition: all 300ms;
    width: 100%;
    height: 100%;
  }

  .content-carrousel img:hover {
    transform: scale(1.2);
    transition: all 300ms;
  }

  @keyframes rotar {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
`;
