import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`
from{
  transform:  rotate(0deg)
 }
 to {
  transform: rotate(360deg)   
 }

`;
export const FAQStyle = styled.div`
  color: #ffffff;
  position: relative;
  overflow: hidden;
  background-color: black;
  z-index: 1;
  min-height: 100vh;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .container {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  .container div {
    position: absolute;
    animation: ${breatheAnimation} 20s backwards infinite;
  }
  .container div:nth-child(1) {
    width: 1191px;
    height: 643px;
    left: 186px;
    bottom: 584px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    transform: rotate(178.01deg);
    opacity: 0.7;
    animation-delay: 5s;
  }
  .container div:nth-child(2) {
    width: 1463.49px;
    height: 217.14px;
    right: -1000.19px;
    top: 399.96px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    animation-delay: 5s;
  }
  .container div:nth-child(3) {
    width: 1463.49px;
    height: 481.4px;
    left: 1080.29px;
    top: 113.11px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    opacity: 0.6;
    transform: rotate(178.01deg);
    animation-delay: 5s;
  }
  .container div:nth-child(4) {
    width: 1000px;
    height: 200px;
    left: 20px;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    animation-delay: 5s;
  }
  .container:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
  }
  .containerOne {
    text-align: center;
    margin: auto;
    width: 50%;
  }
  .header {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: #ffffff;
  }
  .hero-container {
    text-align: center;
    margin: auto;
    width: 35%;

    .flex {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid white;
      flex-flow: row wrap;
      padding: 1.5rem 0;

      .question {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      svg {
        color: #037ef3;
        cursor: pointer;
      }
    }
  }
  .lastdiv {
    background-color: #060d19;
    margin: auto;
    width: 60%;
    margin: 3rem auto;
    padding-bottom: 1rem;
    .middle {
      padding: 1rem 0;
      color: #9d9d9d;
    }
    button {
      color: #ffffff;
      background-color: transparent;
      padding: 0.5rem 2.5rem;
      border: 1px solid #1386f4;
      border-radius: 25px;
      font-size: 17px;
      cursor: pointer;
    }
  }
  .answer {
    text-align: left;
    transition: all 0.5s;
  }
  .show {
    visibility: visible;
  }
  .hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  @media (max-width: 760px) {
    .hero-container {
      text-align: center;
      margin: auto;
      width: 100%;
    }
    .containerOne {
      text-align: center;
      margin: auto;
      width: 100%;
      .header {
        font-size: 24px;
      }
    }
  }
`;
