import React, { useLayoutEffect, useState } from "react";
import BlogHeader from "./components/header";
import SingleBlog from "./components/singleBlog";
import axios from "axios";
// import Loader from "../../components/Loader";
import { Bigsection } from "./style";
import NavBar from "../../components/NavBar";
const Blogs = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    axios
      .get("https://octarback.adgrouptech.com/api/blogs")
      .then(function (response) {
        // handle success
        setIsLoading(false);
        setAllBlogs(response?.data?.data);
        console.log("response?.data?.data", response?.data?.data?.data);
      })
      .catch(function (error) {
        setIsLoading(false);

        // handle error
        console.log(error);
      });
  };
  console.log("allBlogs", allBlogs?.data);
  useLayoutEffect(() => {
    fetchData();
  }, []);
  // if (isLoading) {
  //   return <Loader />;
  // }
  return (
    <Bigsection>
      <NavBar />
      <div class="containerPage">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="blogContainer">
        <BlogHeader />
        {/* <BlogNav /> */}
        <SingleBlog allBlogs={allBlogs?.data} />
      </div>
    </Bigsection>
  );
};

export default Blogs;
