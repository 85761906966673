import styled from "styled-components";
import { keyframes } from "styled-components";

const breatheAnimation = keyframes`
from{
  transform:  rotate(0deg)
 }
 to {
  transform: rotate(360deg)   
 }

`;
export const Bigsection = styled.div`
  justify-content: space-around;
  position: relative;
  padding: 0 1rem;
  padding-top: 5rem;
  overflow: hidden;
  background-color: black;
  z-index: 1;
  min-height: 100vh;
  color: white;
  .containerPage {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  .containerPage div {
    position: absolute;
    animation: ${breatheAnimation} 15s linear infinite;
    animation-duration: 20s;
  }
  .containerPage div:nth-child(3) {
    height: 90%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    bottom: 0;
    border-radius: 50px;
  }
  .containerPage div:nth-child(4) {
    height: 80%;
    width: 70%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    top: 5%;
    right: -5%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .containerPage div:nth-child(2) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    top: 0%;
    animation-delay: 15s;
    border-radius: 50px;
  }
  .containerPage div:nth-child(1) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    bottom: -15%;
    right: -20%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .containerPage:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
  }
  a {
    text-decoration: none;
  }
  .dynamicBlogContainer {
    margin: 0rem 3rem;
    //   min-height: 100vh;
    .nav {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: white;
      p {
        color: white;
        /* Body */
        font-family: Open Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 180%; /* 2.025rem */
      }
      svg {
        filter: invert(100%) sepia(22%) saturate(0%) hue-rotate(174deg)
          brightness(112%) contrast(100%);
      }
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;
