import icon1 from "../assets/ICONS/WHY US/why us_collaborative partnership.svg";
import icon2 from "../assets/ICONS/WHY US/why us_competetive advantage.svg";
import icon3 from "../assets/ICONS/WHY US/why us_comprehensive solutions.svg";
import icon4 from "../assets/ICONS/WHY US/why us_customer satisfaction.svg";
import icon5 from "../assets/ICONS/WHY US/why us_cutting-edge technology.svg";
import icon6 from "../assets/ICONS/WHY US/why us_exceptional quality.svg";
import icon7 from "../assets/ICONS/WHY US/why us_expertise and experience.svg";
import icon8 from "../assets/ICONS/WHY US/why us_outstanding customer support.svg";
import icon9 from "../assets/ICONS/WHY US/why us_storng track record.svg";
import icon10 from "../assets/ICONS/WHY US/why us_tailored approach.svg";
import icon11 from "../assets/ICONS/WHY US/why us_time and cost effeciency.svg";
export const arr = [
  {
    id: 1,
    title: "Comprehensive Solutions",
    des: "We offer a comprehensive range of services tailored specifically for real estate developers. From interactive touch screen solutions to physical models and AR technology, we provide a complete suite of innovative tools to enhance your marketing and presentations.",
    icon: icon1,
  },
  {
    id: 2,
    title: "Expertise and Experience",
    des: "With years of experience in the industry, our team possesses the expertise and knowledge necessary to meet the unique needs of real estate developers. We understand the nuances of the industry and are well-equipped to deliver exceptional results.",
    icon: icon2,
  },
  {
    id: 3,
    title: "Cutting-Edge Technology",
    des: " We stay at the forefront of technological advancements, leveraging the latest tools and techniques to bring your projects to life. Our commitment to staying updated ensures that you benefit from the most innovative solutions available in the market.",
    icon: icon3,
  },
  {
    id: 4,
    title: "Tailored Approach",
    des: " We recognize that every real estate development project is unique. Our services are tailored to meet your specific requirements, allowing you to showcase your projects in the most impactful and effective manner.",
    icon: icon4,
  },
  {
    id: 5,
    title: "Exceptional Quality",
    des: "We take pride in delivering exceptional quality in every aspect of our work. From the accuracy and detail of our physical models to the seamless functionality of our interactive touch screen solutions, we strive for excellence in all that we do.",
    icon: icon5,
  },
  {
    id: 6,
    title: "Customer Satisfaction",
    des: "Our primary goal is customer satisfaction. We prioritize open communication, collaboration, and attention to detail to ensure that we meet and exceed your expectations. Your success is our success.",
    icon: icon6,
  },
  {
    id: 7,
    title: "Strong Track Record",
    des: " Our proven track record of successfully serving real estate developers speaks to our ability to deliver outstanding results. We have a portfolio of satisfied clients who have benefited from our services and achieved their marketing goals.",
    icon: icon7,
  },
  {
    id: 8,
    title: "Time and Cost Efficiency",
    des: " We understand the importance of efficiency in the real estate industry. Our services are designed to save you time and resources, providing streamlined solutions that help accelerate your project timelines and optimize your budget.",
    icon: icon8,
  },
  {
    id: 9,
    title: "Competitive Advantage",
    des: "By choosing our company, you gain a competitive advantage in the market. Our innovative services, cutting-edge technology, and compelling visualizations set your projects apart, attracting more attention and generating greater interest from potential buyers.",
    icon: icon9,
  },
  {
    id: 10,
    title: "Collaborative Partnership",
    des: " We value collaboration and believe in building strong partnerships with our clients. We work closely with you, listening to your needs, providing valuable insights, and adapting our services to align with your vision. Together, we can achieve exceptional outcomes.",
    icon: icon10,
  },
  {
    id: 11,
    title: "Outstanding Customer Support",
    des: " We prioritize exceptional customer support throughout your journey with us. Our dedicated team is always available to address your inquiries, help, and ensure that you have a seamless and positive experience working with our company.",
    icon: icon11,
  },
];
