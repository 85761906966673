import { Button } from "@mui/material";
import styled, { keyframes } from "styled-components";
const breatheAnimation = keyframes`
from{
  transform:  rotate(0deg)
 }
 to {
  transform: rotate(360deg)   
 }



`;
export const ServiceStyle = styled.div`
  color: #ffffff;
  position: relative;
  padding: 5rem 1rem;
  overflow: hidden;
  background-color: black;
  z-index: 1;
  padding-top: 10rem;
  min-height: 100vh;
  .container {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  .container div {
    position: absolute;
    animation: ${breatheAnimation} 10s backwards infinite;
    animation-duration: 10s;
  }
  .container div:nth-child(1) {
    width: 1139.45px;
    height: 233.99px;
    left: 25px;
    top: 352.55px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    opacity: 0.6;
    transform: rotate(161.23deg);
  }
  .container div:nth-child(2) {
    width: 987px;
    height: 270px;
    left: 729px;
    top: 131px;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    opacity: 0.6;
  }
  .container div:nth-child(3) {
    width: 1078px;
    height: 427px;
    left: -138px;
    top: 400px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);

    opacity: 0.4;
  }
  .container:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
  }
  .firstsection {
    .lorem {
      width: 50%;
      padding: 2rem 0;
    }
    h3 {
      font-weight: 700;
      font-size: 48px;
    }
    &:nth-child(1) {
      /* width: 50%; */
    }
    &:nth-child(2) {
      /* width: 100%; */
    }
  }
  .lastsection {
    .links {
      ul {
        display: flex;
        list-style: none;
        li {
          margin-right: 2rem;
          position: relative;
        }
      }
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.4;
        text-decoration: none;
      }
      a.active {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: white;
        opacity: 1;

        li:after {
          position: absolute;
          bottom: -0.9rem;
          background-color: white;
          left: 1px;
          content: "";
          width: 100%;
          height: 1px;
        }
      }
    }
  }
  .sliderContainer {
    p {
      font-size: 16px;
      line-height: 24px;
      width: 70%;
      padding: 2rem 0;
    }
  }
  .myFlex {
    display: flex;
    .myFlexitem {
      flex-basis: 25%;
    }
  }
  .wrapper {
    position: relative;
    width: 250px;
    /* width: 100%; */
    margin-top: 2rem;
    img {
      width: 100%;
      border-radius: 8px;
    }
    button {
      border: 2px solid #ffffff;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      background: transparent;
      color: #ffffff;
      padding: 0.5rem 1rem;
      cursor: pointer;
      margin: 1rem 0;
    }
  }
  .big-container {
    div:nth-child(odd) .wrapper {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 99%;
        background: linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.5s;
        z-index: -1;
        border-radius: 8px;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
    div:nth-child(even) .wrapper {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 99%;
        background: linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.5s;
        z-index: -1;
        border-radius: 8px;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .hiddencontent {
    opacity: 0;

    .content {
      position: absolute;
      left: 0.9rem;
      top: 50%;
      .lorem {
        width: 10%;
      }
    }
    transition-property: opacity;
    transition-duration: 0.5s;
  }

  .wrapper:hover {
    .hiddencontent {
      opacity: 1;
    }

    img {
      opacity: 0;
    }
  }
`;
export const DownButton = styled(Button)`
  && {
    color: white;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    text-decoration: underline;
    margin: 0;
    padding: 0;
  }
`;
export const ImageContainer = styled.div`
  img {
    width: 275px;
    height: 414px;
    padding-right: 10px;
  }
`;
