import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

export const NavStyle = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  /* justify-content: space-around; */
  list-style: none;
  /* padding: 0 1rem; */
  margin: 0;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  backdrop-filter: blur(5px);
  @media (max-width: 1296px) {
    .hide {
      display: none;
      background-color: red;
    }
  }
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin: auto;
    li {
      padding: 1rem 0.9rem 0 0.9rem;
      color: #ffffff;
      font-weight: 500;
    }
  }
`;
export const Mybutton = styled(Button)`
  && {
    text-transform: capitalize;
    font-weight: 400;
    color: #ffffff;
    border: 2px solid #ffffff;
    padding: 0.2rem 1rem;
    font-size: 24px;
    position: relative;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      background: rgb(255, 255, 255);
      transition-duration: 0.9s;
      z-index: -1;
      inset: 0px 0px auto auto;
      width: 0px;
      height: 100%;
      opacity: 1;
    }
    &:hover {
      &:before {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      color: black;
    }
    &:hover {
      color: black;
      background: rgb(255, 255, 255);
      transition: color 0.9s ease 0s, background 0s ease 0.3s;
    }
    @media (max-width: 470px) {
      padding: 0;
      display: none;
    }
  }
`;
export const StyledLink = styled(HashLink)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
export const PageButton = styled(Button)`
  && {
    text-transform: capitalize;
    font-weight: 400;
    color: #ffffff;
    border: 2px solid #ffffff;
    padding: 0.2rem 1rem;
    font-size: 24px;
    position: relative;

    /* &:before {
      position: absolute;
      content: "";
      background: rgb(255, 255, 255);
      transition-duration: 0.9s;
      z-index: -1;
      inset: 0px 0px auto auto;
      width: 0px;
      height: 100%;
      opacity: 1;
    }
    &:hover {
      &:before {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      color: black;
    }
    &:hover {
      color: black;
      background: rgb(255, 255, 255);
      transition: color 0.9s ease 0s, background 0s ease 0.3s;
    } */
  }
`;
