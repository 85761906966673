import styled, { keyframes } from "styled-components";
const breatheAnimation = keyframes`
 from{
  transform: rotate(178.01deg)  
 }
to {
  transform: rotate(178.01deg)   
 }


`;
export const OptionsPage = styled.div`
  height: 100vh;

  .container {
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  .container div {
    position: absolute;
    /* border-radius: 50%; */
    animation: ${breatheAnimation} 10s backwards infinite;
    animation-duration: 10s;
  }
  .container div:nth-child(3) {
    height: 90%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    top: -10%;
    border-radius: 50px;
  }
  .container div:nth-child(4) {
    height: 80%;
    width: 70%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    top: 5%;
    right: -5%;
    /* animation-delay: 0.5s; */
    border-radius: 50px;
  }
  .container div:nth-child(2) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    top: 0%;
    /* animation-delay: 2s; */
    border-radius: 50px;
  }
  .container div:nth-child(1) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    bottom: -15%;
    right: -20%;
    /* animation-delay: 0.5s; */
    border-radius: 50px;
  }
  .container:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
  }
  button {
    margin-bottom: 2px;
    color: white;
    position: absolute;
  }
  .links {
    ul {
      text-align: left;
      li {
        padding: 1rem;
        color: white;
        font-weight: 700;
        font-size: 32px;
      }
    }
  }

  .nav {
    ul {
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      img {
        width: 100%;
      }
      li {
      }
    }
  }
`;
