import React from "react";
import styled from "styled-components";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { StyledLink } from "../style/NavBarStyle";
const SingleFaq = ({ question, answer }) => {
  const [showDiv, setShowDiv] = React.useState(false);

  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };
  return (
    <Section>
      {" "}
      <div className="App">
        <p className="ques">Q : {question}?</p>
        <p onClick={toggleDiv}>
          {showDiv ? (
            <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
          ) : (
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          )}
        </p>
        {/* <button>{showDiv ? "Hide Div" : "Show Div"}</button> */}
        <div className={`my-div ${showDiv ? "show" : "hide"}`}>
          <p>
            A : {answer}{" "}
            {question ===
            "What are the benefits of using VR and AR technology in the classroom " ? (
              <span>
                <StyledLink to="#why" smooth style={{ color: "#89c1f9" }}>
                  Click here
                </StyledLink>
              </span>
            ) : (
              <></>
            )}
          </p>
        </div>
      </div>
    </Section>
  );
};

const Section = styled.div`
  .App {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    flex-flow: row wrap;
    padding: 1.5rem 0;
  }
  .my-div {
    overflow: hidden;
    width: 100%;
    text-align: left;
    padding-top: 10px;
    transition: opacity 0.3s ease-in;
  }

  .show {
    opacity: 1;
  }

  .hide {
    opacity: 0 2s;
  }
  svg {
    cursor: pointer;
  }
  .ques {
    width: 90%;
    text-align: left;
  }
  @media (max-width: 470px) {
    .App {
      justify-content: flex-end;
    }
    .ques {
      width: 100%;
    }
  }
`;
export default SingleFaq;
