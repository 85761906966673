import { Grid } from "@mui/material";
import React from "react";
import { BigSection } from "../style/aboutVrealStyle";
import video from "../assets/video.png";
import donwicon from "../assets/downIcon.svg";
import { ButtonTourDown } from "../style/HeaderStyle";
import { useSelector } from "react-redux";
const AboutVreal = () => {
  const { allPages } = useSelector((state) => state?.pages);
  const about = allPages.find((section) => section.section === "about");
  console.log("about", about);
  return (
    <BigSection id="aboutVreal">
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3>{about?.title}</h3>
      <p>{about?.description}</p>
      <div style={{ width: "80%" }}>
        <img src={video}></img>
      </div>

      <div style={{ textAlign: "center" }}>
        <ButtonTourDown>
          {" "}
          <img className="goDown" src={donwicon} alt="" />
        </ButtonTourDown>
      </div>
    </BigSection>
  );
};

export default AboutVreal;
