import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    
}
  body {
    font-family: 'Roboto', sans-serif;
  }
  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);

      }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
}
.slide-pane__overlay {
    z-index: 500 !important;
    width: 100% !important;
    overflow: hidden !important;

  }
  .slide-pane {
    width: 100% !important;
    overflow: hidden !important;

  }
  .slide-pane__content{
    padding: 0 !important;
  }
  .slide-pane__header{
    display: none;
  }
  .some-custom-overlay-class{
    background-color: transparent !important;

  }
`;
