import React from "react";
import { OptionsPage } from "../style/OptionsStyle";
import { motion } from "framer-motion";
import { StyledLink } from "../style/NavBarStyle";
import { Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/LogoVreal.svg";
const Options = ({ showPage, setShowOptions }) => {
  return (
    <OptionsPage>
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="nav">
        <ul>
          <li>
            {" "}
            <img src={logo} alt="" />{" "}
          </li>
          <li>
            {" "}
            <CloseIcon
              onClick={() => {
                setShowOptions(!showPage);
              }}
              style={{ fontSize: "2rem", color: "white", cursor: "pointer" }}
            ></CloseIcon>
          </li>
        </ul>
      </div>

      <div className="links">
        <ul>
          <StyledLink to="#home">
            {" "}
            <li>00. Home</li>
          </StyledLink>
          <StyledLink to="#about">
            {" "}
            <li>01. About Vreal</li>
          </StyledLink>
          <StyledLink to="#">
            {" "}
            <li>02. Why Vreal</li>
          </StyledLink>
          <StyledLink to="#">
            {" "}
            <li>03. Services</li>
          </StyledLink>
          <StyledLink to="#">
            {" "}
            <li>04. Faq</li>
          </StyledLink>
          <StyledLink to="#">
            {" "}
            <li>05. Success Stories</li>
          </StyledLink>
          <StyledLink to="#">
            {" "}
            <li>06. What People Say</li>
          </StyledLink>
        </ul>
      </div>
    </OptionsPage>
  );
};

export default Options;
