import styled from "styled-components";

export const ServiceChildComponent = styled.div`
  min-height: 60vh;
  transition: 0.5s all;
  img {
    transition: 0.5s all;
  }
  /* padding: 3rem 0; */

  .qualities {
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s;
  }

  .wrapperContnet {
    /* width: 100%; */
    transition: 0.5s all;
  }
  .hiddencontent {
    position: absolute;
    top: 50%;
    left: 70%;
    width: 100%;
    display: none;
    transition: 0.5s all;
    transform: translate(-50%, -50%);
    p {
      font-weight: 300;
      font-size: 14px;
      padding: 0;
    }
    button {
      padding: 0.5rem 2rem;
      background: none;
      color: white;
      border: 2px solid white;
      cursor: pointer;
      font-size: 16px;
      margin-top: 7px;
    }
    .header {
      font-weight: 700;
      font-size: 16px;
      padding-bottom: 8px;
    }
  }
  .wrapper {
    width: 100%;
    height: 400px;
    width: 300px;
    position: relative;
    transition: 0.5s all;
    img {
      width: 100%;
      height: 100%;
      transition: 0.5s all;
    }
  }
  .wrapper {
    transition: 0.5s all;
  }
  .swiper-slide:nth-child(odd) {
    .wrapper:hover {
      background: linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
      border-radius: 4px;
      transition: 0.5s all;
      img {
        display: none;
      }
      .hiddencontent {
        display: block;
      }
    }
  }
  .swiper-slide:nth-child(even) {
    .wrapper {
      transition: 0.5s all;
    }
    .wrapper:hover {
      background: linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
      border-radius: 4px;
      transition: 0.5s all;

      img {
        display: none;
      }
      .hiddencontent {
        display: block;
      }
    }
  }
`;
