import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import img from "../../../assets/singleServiceImg.png";
import styled from "styled-components";

const About = () => {
  const { id } = useParams();
  const { singleService } = useSelector((state) => state?.services);

  return (
    <Bigsection>
      <Grid container columns={12} alignItems="center">
        <Grid item xs={12} md={6}>
          {" "}
          <div>
            <p className="lorem">{singleService?.description}</p>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {" "}
          <div>
            <img src={img}></img>
          </div>
        </Grid>
      </Grid>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .lorem {
    font-size: 18px !important;
    word-spacing: 0.5rem;
  }
  img {
    width: 100% !important;
  }
`;
export default About;
