import React from "react";
import { IconButton, VrealStyle } from "../style/whyVreal";
import icon from "../assets/whyusIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import { ButtonTourDown } from "../style/HeaderStyle";
import donwicon from "../assets/downIcon.svg";
import objects from "../assets/OBJECTS.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Pagination, Navigation } from "swiper";
import "animate.css";
import { useSelector } from "react-redux";
import { arr } from "../Static info/whyArray";

const WhyVreal = ({ scrolTo, ref, goToSectionRef }) => {
  const { allPages } = useSelector((state) => state?.pages);
  const why = allPages.find((section) => section.section === "why");
  console.log("why", why);
  return (
    <>
      <VrealStyle id="why">
        <div class="container">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="whyus">
          <h3>{why?.title}</h3>
          <p>{why?.description}</p>
        </div>
        <div className="qualities">
          <Swiper
            spaceBetween={20}
            slidesPerView={4}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            modules={[Autoplay]}
            className="slide"
            breakpoints={{
              1920: { slidesPerView: 4, spaceBetween: 20 },
              1440: { slidesPerView: 4, spaceBetween: 20 },
              1257: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              639: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              418: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
          >
            {arr?.map((item, index) => {
              return (
                <SwiperSlide key={item.index}>
                  {" "}
                  <div className="wrapper">
                    <div className="wrapperContnet">
                      <div className="thumb">
                        <img src={item?.icon}></img>
                      </div>
                      <p>{item?.title}</p>
                    </div>
                    <div className="hiddencontent">
                      <p>{item?.des}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/* <div>
          {" "}
          <div className="lorem">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              nec ut turpis nulla praesent amet egestas. Nisi dolor viverra nunc
              in.
            </p>
          </div>
          <div className="p_and_buttons">
            <div className="p_and_buttons_content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>{" "}
              <IconButton>
                <AddIcon className="icon"></AddIcon>
              </IconButton>
            </div>
            <div className="p_and_buttons_content">
              <p>
                Lorem ipsum dolodfr sit afdmet, consectdfdetur adipiscing elit.
              </p>{" "}
              <IconButton>
                <AddIcon className="icon"></AddIcon>
              </IconButton>
            </div>
            <div className="p_and_buttons_content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>{" "}
              <IconButton>
                <AddIcon className="icon"></AddIcon>
              </IconButton>
            </div>
            <div className="p_and_buttons_content">
              <p>
                Lorem ipsum dolofdfdr sffrit amfdfet, conseddfctetur
                adiytyypiscing e lit.
              </p>{" "}
              <IconButton>
                <AddIcon className="icon"></AddIcon>
              </IconButton>
            </div>
          </div>
        </div>
        <div className="lastSection">
          <div>
            <h3>This is a headline</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt
              id ut cursus volutpat semper. Urna rutrum tellus vel id odio
              lacus, ut nisl. Placerat libero nullam urna suspendisse sit
              tincidunt. Tempor condimentum amet luctus ut molestie. Dolor id
              pretium nec, volutpat lorem at nisi. Erat sit rutrum senectus
              purus Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Quod sapiente, distinctio officiis, totam vel nemo debitis quo
              eveniet, amet maiores hic ea impedit id nisi enim at! Veritatis,
              vero voluptate.
            </p>
          </div>
          <div>
            <img src={objects}></img>
          </div>
        </div> */}
        <div style={{ textAlign: "center" }}>
          <ButtonTourDown>
            {" "}
            <img className="goDown" src={donwicon} alt="" />
          </ButtonTourDown>
        </div>
      </VrealStyle>
    </>
  );
};

export default WhyVreal;
