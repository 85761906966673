import styled from "styled-components";
import { ServiceStyle } from "./servicesStyle";
import right from "../assets/rightArrow.svg";
import left from "../assets/leftArrow.svg";
import { Button } from "@mui/material";

export const SuccessStyle = styled(ServiceStyle)`
  && {
    justify-content: space-around;
    .container div:nth-child(1) {
      width: 987px;
      height: 465px;
      left: 21px;
      top: -63px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    }
    .container div:nth-child(2) {
      width: 1463.49px;
      height: 481.4px;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
      opacity: 0.6;
    }
    .container div:nth-child(3) {
      width: 1463.49px;
      height: 717.14px;
      left: 288.19px;
      top: 299.96px;
      transform: rotate(178.31deg);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    }
    .container div:nth-child(4) {
      width: 1891px;
      height: 243px;
      right: 15px;
      top: 154px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    }
    .hero {
      h3 {
        font-weight: 700;
        font-size: 32px;
        text-transform: uppercase;
      }
      p {
        width: 39%;
        padding: 2rem 0;
      }
    }
    .slider {
      display: flex;
      /* justify-content: space-between;
      align-content: flex-end; */
      flex-wrap: wrap;
      div {
        padding-right: 1rem;
        .title {
          font-weight: 700;
          font-size: 20px;
          padding-top: 1rem;
        }
        .clientName {
          font-weight: 300;
          font-size: 14px;
          padding: 1rem 0;
        }
      }
      img {
        width: 460px;
        height: auto;
      }
      .mySwiper {
        position: relative;
        .swiper-pagination-bullet {
          background-color: white;
        }
        .swiper-pagination-bullet-active {
          color: #fff;
          background: #007aff;
        }
        .swiper-button-prev:after,
        .swiper-button-next:after {
          /* display: none; */
        }
        .swiper-button-prev,
        .swiper-button-next {
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .swiper-button-prev {
          /* background-image: url(${left}); */
          /* height: 10px; */
          /* width: auto; */
          color: white;
          height: 10px;
          width: auto;
        }
        .swiper-button-next {
          /* background-image: url(${right}); */
          color: white;
          height: 10px;
          width: auto;
        }
        .next-btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
        .prev-btn {
          position: absolute;
          right: 0;
          cursor: pointer;
          background-color: white;
        }
        .singleslide {
          color: white;
        }
      }
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;
export const ShowButton = styled(Button)`
  && {
    padding: 0;
    margin: 0;
    color: white;
  }
`;
