import React from "react";
import { DownButton, ServiceStyle } from "../style/servicesStyle";
import flyingman from "../assets/flyingMan.svg";
import SouthIcon from "@mui/icons-material/South";
import { Link, NavLink, Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import serviceImg from "../assets/serviceTwopng.png";
import SingleService from "./singleService/SingleService";
import SlidingPane from "react-sliding-pane";
import { useSelector } from "react-redux";

const Services = () => {
  const [showService, setShowService] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState("");
  const { allServices } = useSelector((state) => state.services);
  console.log("allServices", allServices);
  return (
    <>
      <ServiceStyle id="services">
        <div class="container">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="firstsection">
          <Grid
            container
            columns={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              {" "}
              <div>
                <h3>Services</h3>
                {/* <p className="lorem">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim
                nisl massa mus tristique est, duis fermentum morbi pretium.
                Varius tellus congue eu faucibus sem amet bibendum a tristique.
              </p>
              <DownButton startIcon={<SouthIcon />}>
                <span className="underline">Know more</span>
              </DownButton> */}
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            columns={12}
            mt={6}
            className="big-container"
            justifyContent="flex-start"
          >
            {allServices.map((e) => {
              return (
                <Grid xs={12} md={3} key={e?.id}>
                  {" "}
                  <div className="wrapper">
                    <div className="imageContainer">
                      <img src={e?.thumbnail_path}></img>
                    </div>
                    <div className="hiddencontent">
                      <div className="content">
                        <p className="header">{e?.title}</p>
                        {/* <p></p> */}
                        <Link to={`single-service/${e?.id}`}>
                          <button onClick={() => setShowService(true)}>
                            Know More
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>

        {/* <div className="lastsection">
        <div className="links">
          <ul>
            <NavLink to="ios">
              {" "}
              <li>IOS</li>
            </NavLink>
            <NavLink to="ibs">
              {" "}
              <li>IBS</li>
            </NavLink>

            <NavLink to="InteractiveModel">
              {" "}
              <li>Physical Modal</li>
            </NavLink>

            <NavLink to="virtualExhibtion">
              {" "}
              <li>Virtual Exhebtion</li>
            </NavLink>
          </ul>
        </div>
        <div className="sliderContainer">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim nisl
            massa mus tristique est, duis fermentum morbi pretium. Varius tellus
            congue eu faucibus sem amet bibendum a tristique.
          </p>
          <Outlet></Outlet>
        </div>
      </div> */}
      </ServiceStyle>
    </>
  );
};

export default Services;
