import { Button } from "@mui/material";
import styled from "styled-components";
import { keyframes } from "styled-components";
const breatheAnimation = keyframes`
from{
  transform:  rotate(0deg)
 }
 to {
  transform: rotate(360deg)   
 }



`;
export const HeaderStyle = styled.header`
  color: #ffffff;
  justify-content: space-around;
  position: relative;
  padding: 0 1rem;
  padding-top: 10rem;
  overflow: hidden;
  background-color: black;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  .flexcontainer {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .videobutton {
    text-align: center;
    /* position:fixed; */
    z-index: 1;
    /* position: relative; */
  }
  .container {
    height: 100vh;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  .container div {
    position: absolute;
    animation: ${breatheAnimation} 15s linear infinite;
    animation-duration: 20s;
  }
  .container div:nth-child(3) {
    height: 90%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    bottom: 0;
    border-radius: 50px;
  }
  .container div:nth-child(4) {
    height: 80%;
    width: 70%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    top: 5%;
    right: -5%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .container div:nth-child(2) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    top: 0%;
    animation-delay: 15s;
    border-radius: 50px;
  }
  .container div:nth-child(1) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    bottom: -15%;
    right: -20%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .container:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
  }
  .content {
    width: 50%;
    position: relative;
    .title {
      font-weight: 900;
      font-size: 80px;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    }
    .descrp {
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 48px;
      color: rgba(255, 255, 255, 0.8);
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    }
    @media (max-width: 1201px) {
      .title {
        font-size: 70px;
      }
      .descrp {
        font-size: 30px;
      }
    }
    @media (max-width: 960px) {
      .title {
        font-size: 60px;
      }
      .descrp {
        font-size: 25px;
      }
    }
    @media (max-width: 563px) {
      width: 90%;
      /* height: 100%; */
      padding: 0;
      .title {
        font-size: 50px;
      }
      .descrp {
        font-size: 20px;
      }
      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      width: 400px;
      height: 1px;
      background-color: white;
      content: "";
      bottom: -2rem;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;
    }
  }
  .lastSection {
    text-align: center;
    ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 100%;
        margin: auto;
        &:nth-child(1) {
          width: calc(100% / 2);
          text-align: left;
          @media (max-width: 709px) {
            button {
              font-size: 21px;
            }
          }
          @media (max-width: 633px) {
            button {
              font-size: 16px;
            }
          }
          @media (max-width: 633px) {
            button {
              font-size: 16px;
            }
          }
          @media (max-width: 505px) {
            button {
              font-size: 12px;
            }
          }
        }
        &:nth-child(3) {
          width: calc(100% / 2);
          text-align: right;
        }
      }
    }
  }
  @media (max-width: 1440) {
    .content {
    }
  }
  @media (max-width: 1200px) {
    .title {
      font-size: 30px;
    }
    .descrp {
      font-size: 20px;
    }
    .content {
      height: 30vh;
    }
  }
`;
export const ButtonVideo = styled(Button)`
  && {
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    font-weight: 500;
    font-size: 24px;
  }
`;
export const ButtonTour = styled(Button)`
  && {
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    font-weight: 500;
    font-size: 24px;
  }
`;
export const ButtonTourDown = styled(Button)`
  && {
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    font-weight: 500;
    font-size: 24px;
    img {
      width: 50%;
    }
    @media (max-width: 709px) {
      img {
        width: 40%;
      }
    }
    @media (max-width: 633px) {
      img {
        /* width: 40%; */
      }
    }
  }
`;
export const ButtonBY = styled(Button)`
  && {
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    font-weight: 500;
    font-size: 24px;
  }
`;
export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
