import React from "react";
import { PeoplesStyle } from "../style/WhatPeopleSayStyle";
import { Grid } from "@mui/material";

const WhatPeopleSay = () => {
  return (
    <PeoplesStyle id="people">
      {" "}
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Grid container columns={12} spacing={5}>
        <Grid item xs={12}>
          <h4>Testimonials </h4>
          <h2>What people say about us</h2>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="card">
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                nec ut turpis nulla praesent amet egestas. Nisi dolor viverra
                nunc in.{" "}
              </p>
            </div>
            <p className="name">John Mike</p>
            <p className="job">CEO at Emaar</p>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="card">
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                nec ut turpis nulla praesent amet egestas. Nisi dolor viverra
                nunc in.{" "}
              </p>
            </div>
            <p className="name">John Mike</p>
            <p className="job">CEO at Emaar</p>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="card">
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                nec ut turpis nulla praesent amet egestas. Nisi dolor viverra
                nunc in.{" "}
              </p>
            </div>
            <p className="name">John Mike</p>
            <p className="job">CEO at Emaar</p>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="card">
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                nec ut turpis nulla praesent amet egestas. Nisi dolor viverra
                nunc in.{" "}
              </p>
            </div>
            <p className="name">John Mike</p>
            <p className="job">CEO at Emaar</p>
          </div>
        </Grid>
      </Grid>
    </PeoplesStyle>
  );
};

export default WhatPeopleSay;
