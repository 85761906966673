import styled, { keyframes } from "styled-components";
import { SinglePysicalModalStyle } from "./SinglePysicalModalStyle";
import { SuccessStyle } from "./SuccessStoriesStyle";
import { HeaderStyle } from "./HeaderStyle";

const breatheAnimation = keyframes`
from{
  transform:  rotate(0deg)
 }
 to {
  transform: rotate(360deg)   
 }

`;
export const SingleStoryStyle = styled.div`
  color: white;
  position: relative;
  z-index: 1;
  background-color: black;
  min-height: 100vh;

  .content {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .container {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  .container div {
    position: absolute;
    animation: ${breatheAnimation} 15s linear infinite;
    animation-duration: 20s;
  }
  .container div:nth-child(3) {
    height: 90%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    bottom: 0;
    border-radius: 50px;
  }
  .container div:nth-child(4) {
    height: 80%;
    width: 70%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    top: 5%;
    right: -5%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .container div:nth-child(2) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(202.01deg, #78ccd0 7.26%, #005aa9 95.99%);
    left: -20%;
    top: 0%;
    animation-delay: 15s;
    border-radius: 50px;
  }
  .container div:nth-child(1) {
    height: 65%;
    width: 80%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(146.43deg, #0ca092 10.91%, #83c88a 85.28%);
    bottom: -15%;
    right: -20%;
    animation-delay: 0.5s;
    border-radius: 50px;
  }
  .container:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(2) {
      ul {
        display: flex;
        align-items: center;
        list-style: none;
      }
    }
  }
  .project-title {
    h3 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      text-transform: uppercase;

      color: #ffffff;
    }
    p,
    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
  img {
    width: 100%;
  }
`;
