import { Grid } from "@mui/material";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { arr13, arr14, arr15, arr16, arr17 } from "./staticArray";
import DynamicSlider from "../../common/DynamicSlider";

const Why = () => {
  const id = useLocation().pathname.split("/")[2];
  let arr = [];
  switch (id) {
    case "13":
      arr = arr13;

      break;
    case "14":
      arr = arr14;
      break;

    case "15":
      arr = arr15;
      break;
    case "16":
      arr = arr16;
      break;

    case "17":
      arr = arr17;
      break;
    default:
      console.log(`Sorry, we are out of ${id}.`);
  }
  return (
    <BigsectionSlider>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <DynamicSlider arr={arr} />
        </Grid>
      </Grid>
    </BigsectionSlider>
  );
};

export const BigsectionSlider = styled.div``;
export default Why;
