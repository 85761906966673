import { Button, Grid } from "@mui/material";
import React from "react";
import { Mybutton, PageButton } from "../style/NavBarStyle";
import icon from "../assets/burgerIcon.svg";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SingleStoryStyle } from "../style/SingleStoyStyle";
import img from "../assets/video.png";
import gameBoy from "../assets/gameBoy.png";
import Footer from "../components/Footer";
const SingleSuccessStory = () => {
  const navigate = useNavigate();
  return (
    <SingleStoryStyle>
      <div className="content">
        <div class="container">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav">
          <div>
            <Button
              sx={{ color: "white" }}
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </Button>
          </div>
          <div>
            <ul>
              <li>
                <Mybutton>Request a demo</Mybutton>
              </li>
              <li>
                <PageButton style={{ border: "none" }}>
                  <img src={icon}></img>
                </PageButton>
              </li>
            </ul>
          </div>
        </div>
        <Grid
          container
          columns={12}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          mt={8}
        >
          <Grid item xs={12} className="project-title">
            <h3>This is a project name</h3>
            <p style={{ padding: ".8rem 0" }}>
              <span>For:</span> This is a Client Name
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              nec ut turpis nulla praesent amet egestas. Nisi dolor viverra nunc
              in.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={img}></img>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Lorem ipsum dolor sit amet</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              nec ut turpis nulla praesent amet egestas. Nisi dolor viverra nunc
              in.
            </p>
          </Grid>
          <Grid item xs={12} md={4} mt={8}>
            <img src={gameBoy}></img>
          </Grid>
          <Grid item xs={12} md={4} mt={8}>
            <img src={gameBoy}></img>
          </Grid>
          <Grid item xs={12} md={4} mt={8}>
            <img src={gameBoy}></img>
          </Grid>
          {/* <Grid item xs={12}>
          {" "}
          <Footer></Footer>
        </Grid> */}
        </Grid>
      </div>
    </SingleStoryStyle>
  );
};

export default SingleSuccessStory;
