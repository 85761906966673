import React from "react";
import { ShowButton, SuccessStyle } from "../style/SuccessStoriesStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useSwiper } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import gameBoy from "../assets/gameBoy.png";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import Slider from "react-slick";
import "../style/slick/slick.css";
import "../style/slick/slick-theme.css";
import { Link } from "react-router-dom";
const SuccessStories = () => {
  const swiper = useSwiper();
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  return (
    <SuccessStyle id="Stories">
      <div class="container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="hero">
        <h3>Our success stories</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec
          ut turpis nulla praesent amet egestas. Nisi dolor viverra nunc in.
        </p>
      </div>
      <div className="slider">
        <div>
          <Link to="single-story">
            <img src={gameBoy}></img>

            <p className="title">Project Name</p>
            <p className="clientName">Client Name</p>
            <ShowButton endIcon={<NorthEastIcon></NorthEastIcon>}>
              Show Case
            </ShowButton>
          </Link>
        </div>
        <div>
          <Link to="single-story">
            <img src={gameBoy}></img>

            <p className="title">Project Name</p>
            <p className="clientName">Client Name</p>
            <ShowButton endIcon={<NorthEastIcon></NorthEastIcon>}>
              Show Case
            </ShowButton>
          </Link>
        </div>
        <div>
          <Link to="single-story">
            <img src={gameBoy}></img>

            <p className="title">Project Name</p>
            <p className="clientName">Client Name</p>
            <ShowButton endIcon={<NorthEastIcon></NorthEastIcon>}>
              Show Case
            </ShowButton>
          </Link>
        </div>
      </div>
    </SuccessStyle>
  );
};

export default SuccessStories;
