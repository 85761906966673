import React from "react";
import {
  Mybutton,
  NavStyle,
  PageButton,
  StyledLink,
} from "../style/NavBarStyle";
import logo from "../assets/LogoVreal.svg";
import { Grid } from "@mui/material";
import icon from "../assets/burgerIcon.svg";
import Options from "./Options";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { HashLink } from "react-router-hash-link";

const NavBar = ({ children, sticky = false, className, ...rest }) => {
  const [showOptions, setShowOptions] = React.useState(false);
  const [isSticky, setIsSticky] = React.useState(false);
  const ref = React.useRef();

  // mount
  React.useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        {
          threshold: [1],
          // rootMargin: '-1px 0px 0px 0px',  // alternativly, use this and set `top:0` in the CSS
        }
      );

    observer.observe(cachedRef);

    // unmount
    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  const [state, setState] = React.useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  return (
    <>
      <Grid container columns={12}>
        <Grid item xs={12}>
          {" "}
          <NavStyle
            className={className + (isSticky ? " isSticky" : "")}
            ref={ref}
            {...rest}
          >
            <div>
              <ul>
                <li>
                  <img src={logo} alt="" />
                </li>
              </ul>
            </div>
            <div className="hide">
              <ul>
                <StyledLink to="/#home" smooth>
                  {" "}
                  <li>Home</li>
                </StyledLink>
                <StyledLink to="/#aboutVreal" smooth>
                  {" "}
                  <li>About Vreal</li>
                </StyledLink>
                <StyledLink to="/#why" smooth>
                  {" "}
                  <li>Why Vreal</li>
                </StyledLink>
                <StyledLink to="/#services" smooth>
                  {" "}
                  <li>Services</li>
                </StyledLink>
                <StyledLink to="/#questions" smooth>
                  {" "}
                  <li>Faq</li>
                </StyledLink>
                <StyledLink to="/#Stories" smooth>
                  {" "}
                  <li>Success Stories</li>
                </StyledLink>

                <StyledLink to="/#people" smooth>
                  {" "}
                  <li>What People Say</li>
                </StyledLink>
                <StyledLink to="/blogs" smooth>
                  {" "}
                  <li>Blogs</li>
                </StyledLink>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <Mybutton>Request a demo</Mybutton>
                </li>
                <li>
                  <PageButton
                    onClick={() => {
                      setShowOptions(!showOptions);
                    }}
                    style={{ border: "none" }}
                  >
                    <img src={icon}></img>
                  </PageButton>
                </li>
              </ul>
            </div>
          </NavStyle>
        </Grid>
      </Grid>

      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={showOptions}
        from="left"
      >
        <Options
          showPage={showOptions}
          setShowOptions={setShowOptions}
        ></Options>
      </SlidingPane>
    </>
  );
};

export default NavBar;
