import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Pagination, Navigation } from "swiper";
import styled from "styled-components";
const DynamicSlider = ({ arr }) => {
  console.log("arr", arr);
  return (
    <Bigsection>
      <div className="qualities">
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
          className="slide"
          breakpoints={{
            1920: { slidesPerView: 4, spaceBetween: 20 },
            1440: { slidesPerView: 4, spaceBetween: 20 },
            1257: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            639: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            418: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {arr?.map((item, index) => {
            return (
              <SwiperSlide key={item.index}>
                {" "}
                <div className="wrapper">
                  <div className="wrapperContnet">
                    <div className="thumb">
                      <img src={item.icon}></img>
                    </div>
                    <p>{item?.title}</p>
                  </div>
                  <div className="hiddencontent">
                    <p>{item?.slide}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .swiper {
    width: 100%;
  }
  .wrapper:nth-child(odd) {
    background: linear-gradient(
      146.43deg,
      rgba(12, 160, 146, 0.4) 10.91%,
      rgba(131, 200, 138, 0.4) 85.28%
    );
    border: 2px solid #ffffff;
    border-radius: 4px;
  }
  .swiper-slide:nth-child(even) {
    .wrapper {
      background: linear-gradient(
        202.01deg,
        rgba(120, 204, 208, 0.4) 7.26%,
        rgba(0, 90, 169, 0.4) 95.99%
      );
      border: 2px solid #ffffff;
      border-radius: 4px;
    }
  }
  .qualities {
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s;
    .hiddencontent {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: none;
      width: 100%;
    }
    .wrapper {
      transition: 0.5s all;
      width: 100%;
      height: 360px;
      position: relative;
      text-align: center;
      &:hover {
        background: none;
        color: black;
        background-color: white;
        .hiddencontent {
          display: block;
          z-index: 999;
          color: black;
          text-align: center;
          margin: auto;
          p {
            color: black;
            padding: 0 3rem;
          }
        }
      }
    }
    .wrapperContnet {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      p {
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
      }
      .thumb {
        height: 70px;
        width: 70px;
        border: 4px solid white;
        border-radius: 50%;
        padding: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 80%;
        object-fit: cover;
      }
    }
  }
  .lorem {
    width: 50%;
  }
  .p_and_buttons_content {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    p {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 24px;
    }
  }
  .secondpart {
    background-color: black;
  }
  .lastSection {
    padding-top: 2rem;
    display: flex;
    justify-content: space-around;
    div:nth-child(1) {
      width: 50%;
      h3 {
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
      }
      p {
        /* width: 60%; */
        padding: 2rem 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }
    }
    div:nth-child(2) {
      width: 50%;
      padding-left: 35px;
    }
  }
`;

export default DynamicSlider;
