import React from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { BigsectionSlider } from "../why/Why";
import { arr13, arr14, arr15, arr16, arr17 } from "./staticArrays";
import DynamicSlider from "../../common/DynamicSlider";
const HowItWorks = () => {
  const id = useLocation().pathname.split("/")[2];
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };
  let arr = [];
  switch (id) {
    case "13":
      arr = arr13;
      break;

    case "14":
      arr = arr14;
      break;

    case "15":
      arr = arr15;
      break;

    case "16":
      arr = arr16;
      break;

    case "17":
      arr = arr17;
      break;

    default:
      console.log(`Sorry, we are out of ${id}.`);
  }
  return (
    <BigsectionSlider>
      {/* {id === "13" ? (
        <>
          <p className="lorem">
            <p className="lorem">
              ORS simplifies the process of property selection and reservation,
              providing an easy and convenient way for clients to explore,
              select, and reserve properties online.
            </p>
            <h3> For the Real Estate Developer </h3>
            <ul>
              <li>You send us your project files (PDF, 3D files)</li>
              <li> Our Team study it and send you a competitive quotation.</li>
              <li>
                You will get your solution in a simple link with a dashboard to
                control your units.
              </li>
              <li>
                Share the link everywhere to get the best qualified leads with
                approx. free cost.{" "}
              </li>
            </ul>

            <h3>For the property buyer </h3>
            <ul>
              <li>
                Property Creation: Our team creates a digital replica of your
                property, including all the necessary features and details
              </li>
              <li>
                Virtual Tour: Clients can take a virtual tour of the property,
                exploring every corner and experiencing the property as if they
                were there in person.
              </li>
              <li>
                Unit Selection: Clients can select the units they want based on
                their preferences, including unit type, location, view, and
                other customizable features.
              </li>
              <li>
                Availability Check: Clients can check the availability of the
                selected units in real-time and receive immediate feedback.
              </li>
              <li>
                Reservation: Clients can reserve the units they want online,
                without having to visit the property physically
              </li>
              <li>
                Completion: The reservation process is completed, and clients
                can proceed to the next step of purchasing the property.
              </li>
            </ul>
          </p>
        </>
      ) : (
        <p></p>
      )}
      {id === "14" ? (
        <>
          <p className="lorem">
            <ul>
              <li>
                Immersive Visualization: Experience a realistic representation
                of the property, combining physical models with interactive
                elements for captivating visual immersion.
              </li>
              <li>
                {" "}
                Interactive Exploration: Engage actively with the model,
                exploring different units and features, enabling informed
                decision-making.
              </li>
              <li>
                Enhanced Engagement: The combination of physical and interactive
                elements creates a memorable experience, increasing client
                engagement and interest.
              </li>
              <li>
                Customized Information: Access tailored details for each unit or
                area through interactive elements, empowering clients to
                evaluate based on their needs.
              </li>
              <li>
                Market Differentiation: Stand out from competitors by offering
                innovative Interactive Model service, showcasing your property
                uniquely.
              </li>
              <li>
                Memorable Impressions: Create lasting impact through the
                interactive nature of the model, enhancing client connection and
                conversion potential.
              </li>
            </ul>
          </p>
        </>
      ) : (
        <p></p>
      )}
      {id === "15" ? (
        <>
          <p className="lorem">
            <ul>
              <li>
                Initial Consultation: We start by understanding your unique
                requirements, goals, and vision for the project during an
                initial consultation. This helps us tailor our IBS service
                precisely to your needs.
              </li>
              <li>
                {" "}
                3D File Development: Our skilled professionals transform your
                architectural plans and designs into highly realistic and
                immersive 3D files. These files showcase your properties' unique
                features, giving potential buyers an accurate representation.
              </li>
              <li>
                Interactive Touch Screen Integration: We seamlessly integrate
                the 3D files into our interactive touch screen technology.
                Placed strategically in your sales office or showroom, these
                touch screens provide a captivating platform for potential
                buyers to engage with the interactive content.
              </li>
              <li>
                User-Friendly Interface: Our user-friendly interface ensures
                effortless navigation for potential buyers. They can explore
                different spaces, zoom in and out, and rotate the view, gaining
                a comprehensive understanding of the property's layout and
                design.
              </li>
              <li>
                Customization Dashboard: We provide a dashboard that allows
                potential buyers to customize their units. Through the
                dashboard, they can select finishes, modify floor plans, and
                visualize the personalized space to match their preferences.
              </li>
              <li>
                Engaging and Informative Experience: The interactive touch
                screen and customization dashboard create an engaging and
                informative experience for potential buyers. They can virtually
                walk through the property, explore various customization
                options, and make informed decisions about their future unit.
              </li>
              <li>
                Expert Guidance and Support: Throughout the process, our team
                offers expert guidance and support. We ensure that the
                interactive experience aligns with your branding and marketing
                objectives, and we provide technical assistance and answer any
                questions that arise.
              </li>
            </ul>
          </p>
        </>
      ) : (
        <p></p>
      )} */}
      <DynamicSlider arr={arr} />
    </BigsectionSlider>
  );
};

export default HowItWorks;
